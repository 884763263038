
import Vue, { PropType } from 'vue'

export type CommunitySubMenu = {
  icon: string
  title: string
  key: string
}

export default Vue.extend({
  name: 'CommunitySubMenu',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Array as PropType<CommunitySubMenu[]>,
      default: () => {
        return [
          {
            icon: '$commDiscordIcon',
            title: 'Discord',
            key: '__discord',
          },
          {
            icon: '$twitterSocialIcon',
            title: 'Twitter',
            key: '__twitter',
          },
          {
            icon: '$commYoutubeIcon',
            title: 'Youtube',
            key: '__youtube',
          },
        ]
      },
    },
  },
  methods: {
    communityItemPressed(page: string) {
      this.$router.push(`/community/${page}`)
    },
    menuItemLink(menuItem: CommunitySubMenu) {
      if (process.browser)
        switch (menuItem.title) {
          case 'Youtube':
            return 'https://www.youtube.com/channel/UCnui50w5BC_P7pfrF0XwoKg'
          case 'Twitter':
            return 'https://twitter.com/machinationsio'
          default:
            return 'https://discord.gg/EqKqWAJ'
        }
    },
  },
})
