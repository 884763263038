const language = {
  activityFeedViewTitle: 'Recent Activity',
  activityFeedTypeFollowUser: '{author} started following {user}',
  activityFeedTypeFollowUserCurrentUser: '{author} started following you',
  activityFeedTypeFollowUserCurrentUserOther: 'You started following {user}',

  activityFeedTypeShareDiagram: "{author} shared {user}'s diagram",
  activityFeedTypeShareDiagramCurrentUser: '{author} shared your diagram',
  activityFeedTypeShareDiagramCurrentUserOther: "You shared {user}'s diagram",

  activityFeedTypeLikeDiagram: "{author} liked {user}'s diagram",
  activityFeedTypeLikeDiagramCurrentUser: '{author} liked your diagram',
  activityFeedTypeLikeDiagramCurrentUserOther: "You liked {user}'s diagram",

  activityFeedTypeCloneDiagram: "{author} forked {user}'s diagram",
  activityFeedTypeCloneDiagramCurrentUser: '{author} forked your diagram',
  activityFeedTypeCloneDiagramCurrentUserOther: "You forked {user}'s diagram",

  activityFeedTypeCreateDiagram: '{author} published a new diagram',
  activityFeedTypeCreateDiagramCurrentUser: 'You published a new diagram',

  activityFeedTypeUpdateDiagram: '{author} updated a diagram',
  activityFeedTypeUpdateDiagramCurrentUser: 'You updated a diagram',

  activityFeedTypePublishTemplate: '{author} added a new template',
  activityFeedTypePublishTemplateCurrentUser: 'You added a new template',

  activityFeedTypeTrendingDiagram: "{author}'s diagram started trending",
  activityFeedTypeTrendingDiagramCurrentUser: 'Your diagram started trending',

  activityFeedTypeTrendingUser: '{author} started trending',
  activityFeedTypeTrendingUserCurrentUser: 'Your profile started trending',

  activityFeedTypeTrendingTemplate: 'Template started trending',
  activityFeedTypeTrendingTemplateCurrentUser: 'Your template started trending',

  diagramDescriptionDefault: 'This diagram doesn’t have any description yet',
  tagsDescriptionDefault: 'This diagram doesn’t have any tags yet',

  viewAllDesigners: 'See All',
  viewAllDiagrams: 'See All',
  viewAllResults: 'See all results',

  designersOfTheMonth: 'Designers of the Month',
  designerOfTheMonth: 'Designer of the Month',
  diagramsOfTheMonth: 'Diagrams of the Month',
  diagramOfTheMonth: 'Diagram of the Month',
  popularDiagrams: 'Popular Diagrams',

  communityHomeDesignersTab1: 'Newest Designers',
  communityHomeDesignersTab2: 'Popular Designers',
  communityHomeDesignersTab3: 'Designers of the Month',
  communityHomeDiagramsTab1: 'Newest',
  communityHomeDiagramsTab2: 'Popular',
  communityHomeDiagramsTab3: 'Diagrams of the Month',
  designersTitle: 'Designers',
  designersProfiles: 'Designers Profiles',
  diagramsTitle: 'Diagrams',
  publicDiagramsTitle: 'Public Diagrams',
  communityHeaderTitle: 'Explore the World’s Top Game Design Community',
  designersSearchResultsNumber:
    'Showing {count} result for <b>{searchValue}</b> | Showing {count} results for <b>{searchValue}</b>',
  designersSearchResultsBack: 'back to see all designers',
  communityDesignersTab1: 'Newest',
  communityDesignersTab2: 'Popular',
  communityDesignersTab3: 'Designers of the Month',
  communityDesignersForHire: 'Designers for Hire',
  communityDiagramsTab1: 'Newest',
  communityDiagramsTab2: 'Popular',
  communityDiagramsTab3: 'Diagrams of the Month',
  hireModalTitle: 'Hire Designer',
  hireModalName: 'First and Last Name*',
  hireModalEmail: 'Email Address*',
  hireModalCompany: 'Company',
  hireModalGame: 'Project Name',
  hireModalTextboxPlaceholder: 'Your Message*',
  hireModalTextPrivacy: "By sending this message you agree to Machinations'",
  hireModalSendMessage: 'Send Message',
  hireModalSuccessMessage: 'An Email has been sent to ',

  contactModalTitle: 'Contact Designer',

  countFollowers: '{count} Follower | {count} Followers',
  countDiagrams: '{count} Diagram | {count} Diagrams',

  followCapital: 'Follow',
  unfollowCapital: 'Unfollow',
  privateUserCapital: 'Private user',
  followingCapital: 'Following',

  openInMachinations: 'Open in My Machinations',

  topTrendingDiagrams: 'Top trending diagrams',

  tabProfile2: 'Liked',
  tabProfile3: 'Following',
  tabProfile4: 'Followers',

  profileFollowingNoDataTitle: 'Not following anyone',
  profileFollowingNoDataSubtitleCurrentUser:
    "Who's your next inspiration going to come from?",
  profileFollowingNoDataSubtitle: '{user} is not following anyone yet',
  profileFollowersNoDataTitle: 'No followers',
  profileFollowersNoDataSubtitleCurrentUser:
    'Make some waves: follow, like and share your diagrams and get noticed',
  profileFollowersNoDataSubtitle: '{user} has no followers yet',

  backHome: 'Back to community',
  notFoundText: 'The page you’re looking for is missing or under maintenance!',

  loginDialogBtn: 'Log in',
  loginDialogTitle:
    'To {sourceAction}, please create a free public account',
  fork: 'Fork',
  backLink: 'Back',
  exploreLink: 'Explore',

  wordLikeCapital: 'Like',
  wordUnlikeCapital: 'Unlike',
  wordCapitalLike: 'Like',
  wordCapitalUnlike: 'Unlike',
  wordCapitalOpen: 'Open',
  wordCapitalFork: 'Fork',
  wordCapitalCareers: 'Careers',
  wordCapitalDocs: 'Docs',
  wordCapitalResources: 'Resources',
  wordCapitalArticles: 'Articles',
  wordCapitalAuthor: 'Author',
  wordCapitalPartners: 'Partners',
  wordCapitalAcademy: 'Academy',

  openInNewTab: 'Open in new tab',
  copyLink: 'Copy link',
  sendMessageCopy: 'Send me a copy of this message via email',

  tooltipClearSearch: 'Clear search results',

  wordCapitalNotifications: 'Notifications',
  wordCapitalMachinations: 'Machinations',
  wordAnd: 'and',
  wordCapitalAnd: 'And',
  wordOr: 'or',
  wordCapitalOr: 'Or',
  wordSave: 'save',
  wordCapitalSave: 'Save',
  wordDone: 'done',
  wordCapitalDone: 'Done',
  wordBio: 'bio',
  wordCapitalBio: 'Bio',
  wordPassword: 'password',
  wordCapitalPassword: 'Password',
  wordRole: 'role',
  wordCapitalRole: 'Role',
  wordFacebook: 'facebook',
  wordCapitalFacebook: 'Facebook',
  wordLinkedin: 'linkedIn',
  wordCapitalLinkedin: 'LinkedIn',
  wordTwitter: 'twitter',
  wordCapitalTwitter: 'Twitter',
  wordDiscord: 'discord',
  wordCapitalDiscord: 'Discord',
  wordChange: 'change',
  wordCapitalChange: 'Change',
  wordJoined: 'joined',
  wordCapitalJoined: 'Joined',
  wordUpgrade: 'upgrade',
  wordCapitalUpgrade: 'Upgrade',
  wordNext: 'next',
  wordNew: 'new',
  wordCapitalNext: 'Next',
  wordBack: 'back',
  wordCapitalBack: 'Back',
  wordBuy: 'buy',
  wordCapitalBuy: 'Buy',
  wordPublic: 'public',
  wordCapitalPublic: 'Public',
  wordPrivate: 'private',
  wordCapitalPrivate: 'Private',
  wordDocumentation: 'documentation',
  wordCapitalDocumentation: 'Documentation',
  wordConfirm: 'confirm',
  wordCapitalConfirm: 'Confirm',
  wordCapitalContact: 'Contact',
  warning: 'Warning',
  wordUnlimited: 'unlimited',
  wordCapitalUnlimited: 'Unlimited',
  wordOf: 'of',
  wordCapitalFeature: 'Feature',
  wordMembers: 'members',
  wordCapitalMembers: 'Members',
  wordGames: 'games',
  wordCapitalGames: 'Games',
  wordProfile: 'profile',
  wordCapitalProfile: 'Profile',
  wordRejected: 'rejected',
  wordCapitalRejected: 'Rejected',
  wordDeclined: 'declined',
  wordCapitalDeclined: 'Declined',
  wordAccepted: 'accepted',
  wordCapitalAccepted: 'Accepted',
  wordMonth: 'month',
  wordCapitalMonth: 'Month',
  wordYear: 'year',
  wordCapitalYear: 'Year',
  wordUsage: 'usage',
  wordCapitalUsage: 'Usage',
  wordAmount: 'amount',
  wordCapitalAmount: 'Amount',
  wordVoid: 'void',
  wordCapitalVoid: 'Void',
  wordInvalid: 'invalid',
  wordCapitalInvalid: 'Invalid',
  wordPaid: 'paid',
  wordCapitalPaid: 'Paid',
  wordPay: 'pay',
  wordCapitalPay: 'Pay',
  wordCard: 'card',
  wordCapitalCard: 'Card',
  wordStripe: 'stripe',
  wordCapitalStripe: 'Stripe',
  wordSuspend: 'suspend',
  wordCapitalSuspend: 'Suspend',
  wordUnsuspend: 'unsuspend',
  wordCapitalUnsuspend: 'Unsuspend',
  wordTeam: 'team',
  wordCapitalTeam: 'Team',
  wordDecline: 'decline',
  wordCapitalDecline: 'Decline',
  wordCheck: 'check',
  wordCapitalUnknown: 'Unknown',
  wordSince: 'since',
  wordCapitalCrypto: 'Web3',
  wordCapitalDiagrams: 'Diagrams',
  wordCapitalDesigners: 'Designers',
  wordCapitalViews: 'Views',
  wordCapitalFollow: 'Follow',
  wordCapitalUnfollow: 'Unfollow',
  wordCapitalFollowing: 'Following',
  wordCapitalFollowers: 'Followers',
  wordCapitalSort: 'Sort:',
  wordCapitalOldest: 'Oldest',
  wordYou: 'you',
  wordCapitalYou: 'You',
  wordCapitalLike: 'Like',
  wordCapitalUnlike: 'Unlike',
  wordCapitalYour: 'Your',
  wordLiked: 'liked',
  wordForked: 'forked',
  wordCapitalFile: 'File',
  wordCapitalHelp: 'Help',
  wordCapitalRename: 'Rename',
  wordCapitalPrint: 'Print',
  wordCapitalShare: 'Share',
  wordCapitalSettings: 'Settings',
  wordCapitalNew: 'New',
  wordCapitalOpen: 'Open',
  wordCapitalCustom: 'Custom',
  wordUser: 'user',
  wordUsers: 'users',

  // Start of HOMEPAGE
  // freeForeverNoCreditCard: 'Free forever, no credit card required',
  watchDemo: 'Watch Demo',
  reviewsDescription: 'Based on user reviews.',
  // End of HOMEPAGE

  'Execution Chart': 'Execution Chart',
  paymentPlan: 'Payment Plan',
  sendInvite: 'Send Invite',
  newTemplate: 'New From Template',
  machinationsFormat: 'Common Properties',
  scale: 'Scale',
  color: 'Color',
  copyright: '© 2024 Machinations SARL',
  quickRunType: 'Quick Run',
  interactiveRunType: 'Interactive Run',
  multipleRunType: 'Multiple Run',
  autosaveDisabled: 'Cannot autosave an empty graph.',
  functional: 'Functional',
  thickness: 'Thickness',
  selectVertices: 'Select Nodes',
  selectEdges: 'Select Connections',
  makeACopy: 'Make a copy',
  formatPanel: 'Properties',
  functional_palette: 'Functional',
  document_details_palette: 'Document Details',
  page_setup_palette: 'Page Setup',
  interval: 'Time Interval',
  type: 'Type',
  resourceTransfer: 'Transfer',
  colorCoding: 'Color coding',
  limits: 'Limits',
  maxValue: 'maximum',
  minValue: 'minimum',
  shuffleSource: 'Shuffle Origin',
  distribution_mode: 'Distribution Mode',
  distribution_mode_variable_speed: 'Variable Speed',
  distribution_mode_instantaneous: 'Instantaneous',
  document_author: 'Author',
  document_creation_date: 'Creation Date',
  document_last_change: 'Last Change',
  document_size: 'Size',
  document_no_components: 'Components',
  document_file_token: 'Diagram Token',
  ungroupSelected: 'Ungroup Selection',
  value: 'Value',
  initial: 'Initial',
  step: 'Step',
  stepNr: 'Step {step}',
  nrOfSteps: '{steps} Step | {steps} Steps',
  nrOfStepsEach: '{steps} Step each | {steps} Steps each',
  stepsBetween: 'steps between {minSteps} and {maxSteps}',
  totalSteps: 'Total Steps {steps}',
  statsNotAvailable: 'Stats not available',
  indicator: 'Indicator',
  indicators: 'Indicators',
  median: 'Median',
  mean: 'Mean',
  mode: 'Mode',
  min: 'Min',
  max: 'Max',
  meanAvg: 'Mean average',
  medianAvg: 'Median average',
  modeAvg: 'Mode average',
  minVal: 'Min value',
  maxVal: 'Max value',
  interactive: 'Interactive',
  label: 'Label',
  formula: 'Formula',
  formulaExamples: 'Examples',
  formulaRuntimeValuePlaceholder: 'Runtime Value',
  formulaIntervalTitle: 'Interval',
  distributionDeterministic: 'Deterministic',
  distributionRandom: 'Random',
  chartPanel: 'Chart',
  chart: 'Chart',
  playHistory: 'Play History',
  myPlayHistory: 'My Play History',
  teamPlayHistory: 'Team Play History',
  executionChart: 'Execution Chart',
  replayExecution: 'Replay Execution',
  stopExecution: 'Stop Execution',
  batchPlayStatusUpdate:
    "<b>{diagramName}</b> <br> Completed plays: {doneCount}/{totalCount} ({percentageDone}%).View in <a class='toast-link-focus-play-history-entry' style='text-decoration: underline; cursor: pointer;'>Play History</a>",
  batchPlaysFinished:
    "<b>{diagramName}</b> <br> Predictions execution finished. View in <a class='toast-link-focus-play-history-entry' style='text-decoration: underline; cursor: pointer;'>Play History</a>",
  failedInitializingQuickPlaysBatch: 'Failed initializing quick plays batch',
  failedLoadingPlayHistoryVersions: 'Failed loading play history versions',
  failedLoadingPlaysForVersion: 'Failed loading plays for this version',
  failedLoadingPlayValues: 'Failed loading values for play',
  failedStoppingBatchExecution: 'Failed stopping quick plays execution',
  failedDeletingPlaysInVersion: 'Failed deleting plays in this version',
  failedDeletingPlaysInBatch: 'Failed deleting plays in this batch',
  failedDeletingPlay: 'Failed deleting this play',
  failedUpdatingVersion: 'Failed updating diagram version details',
  failedExecutingPlay: 'Failed executing play',
  failedExecutingQuickPlaysBatch: 'Failed executing quick plays batch',
  stoppedByUser: 'Stopped by user',
  createdAt: 'Created at {date}',
  executedAt: 'Executed at {date}',
  download: 'Download',
  import: 'Import',
  inChart: 'Show in Chart',
  legendDelete: 'Remove from chart',
  legendHighlight: 'Highlighted',
  legendLeftScale: 'Use left scale',
  legendRightScale: 'Use right scale',
  legendColor: 'Color',
  legendLineChart: 'Line Chart',
  legendAreaChart: 'Area Chart',
  legendBarChart: 'Bar Chart',
  legendEmpty:
    "Right click on a Node and check 'Show in Chart' to display values",
  legendCellVisibility: 'Visible',
  diagram_dice: 'Dice',
  diagram_skill: 'Skill',
  diagram_strategy: 'Strategy',
  diagram_multiplayer: 'Multiplayer',
  diagram_time_steps_limit: 'Time Steps Limit',
  yesDeleteDiagram: 'Yes, delete diagram',
  deleteThisDiagram: 'Delete this diagram?',
  deleteThisSharedDiagram: 'Delete this shared diagram?',
  permanentlyDeleteDiagram:
    'Permanently delete <b> {diagramName} </b> from your account?',
  permanentlyDeleteSharedDiagram:
    'Permanently delete shared diagram <b> {diagramName} </b> from your account?',
  saveAsTemplate: 'Save as template',
  saveAsTest: 'Save as test',
  toPrivateLibrary: 'Add to private library',
  toPrivateLibraryToast: 'Add to private library',
  toTeamLibrary: 'Add to team library',
  toTeamLibraryToast: 'Add to team library',
  toPublicLibrary: 'Add to public library',
  library: 'Library',
  emptyPrivateLibraryTitle: 'Your private library is empty.',
  emptyPrivateLibraryTextFreePlan:
    'Up to <b>{limitNo}</b> Library items included in the <b>Public plan</b>. Select multiple Nodes on the diagram, right click them and select “Add to private library”',
  emptyPrivateLibraryTextPaidPlan:
    'Up to <b>{limitNo}</b> Library items included in the <b>{plan} plan</b>. Select multiple Nodes on the diagram, right click them and select “Add to private library”',
  emptyTeamLibraryTitle: 'Your team library is empty.',
  emptyTeamLibraryText:
    'Up to <b>{limitNo}</b> Library items included in the <b>{plan} plan</b>. Select multiple Nodes on the diagram, right click them and select “Add to private library”',
  libraryName: 'Library Name',
  deleteAccountConfirmation: 'Are you sure you want to delete your account?',
  alreadyConnected: 'Nodes already connected',
  cancel: 'Cancel',
  close: 'Close',
  back: 'Back',
  'collapse-expand': 'Collapse/Expand',
  containsValidationErrors: 'Contains validation errors',
  doubleClickOrientation: 'Doubleclick to Change Orientation',
  error: 'Error',
  errorSavingFile: 'Error saving file',
  ok: 'OK',
  updatingDocument: 'Updating Document. Please wait...',
  updatingSelection: 'Updating Selection. Please wait...',
  about: 'About',
  activation: 'Activation',
  actualSize: 'Actual Size',
  add: 'Add',
  addLayer: 'Add Layer',
  addNote: 'Add a note',
  addProperty: 'Add Property',
  addToExistingDrawing: 'Add to Existing Drawing',
  addWaypoint: 'Add Waypoint',
  advanced: 'Advanced',
  align: 'Align',
  alignment: 'Alignment',
  allChangesLost: 'All changes will be lost!',
  angle: 'Angle',
  anyoneWithTheLink: 'Anyone with the link can view',
  apply: 'Apply',
  arc: 'Arc',
  arrange: 'Arrange',
  arrow: 'Arrow',
  arrows: 'Arrows',
  automatic: 'Automatic',
  autosave: 'Autosave',
  autosize: 'Autosize',
  background: 'Background',
  backgroundColor: 'Background Color',
  backgroundImage: 'Background Image (url)',
  basic: 'Basic',
  block: 'Block',
  blockquote: 'Blockquote',
  bold: 'Bold',
  borderWidth: 'Borderwidth',
  borderColor: 'Border Color',
  bottom: 'Bottom',
  bottomAlign: 'Bottom Align',
  bottomLeft: 'Bottom Left',
  bottomRight: 'Bottom Right',
  bulletedList: 'Bulleted List',
  cannotOpenFile: 'Cannot open file',
  selectedLayerLocked: 'Selected layer is locked!',
  unstableConnection: 'Communication with the server is unstable',
  center: 'Center',
  change: 'Change',
  changeOrientation: 'Change Orientation',
  checkOtherCategories:
    'Check out the other categories or try different keywords',
  circle: 'Circle',
  classic: 'Classic',
  clearDefaultStyle: 'Clear Default Style',
  clearWaypoints: 'Clear Waypoints',
  clipart: 'Clipart',
  collapse: 'Collapse',
  collapseExpand: 'Collapse/Expand',
  collapsible: 'Collapsible',
  comic: 'Comic',
  connect: 'Connect',
  connection: 'Connection',
  connectionPoints: 'Connection points',
  connectionArrows: 'Connection arrows',
  constrainProportions: 'Constrain Proportions',
  copy: 'Copy',
  copyConnect: 'Copy on Connect',
  copyLink: 'Copy link',
  create: 'Create',
  curved: 'Curved',
  custom: 'Custom',
  cut: 'Cut',
  dashed: 'Dashed',
  decreaseIndent: 'Decrease Indent',

  delete: 'Delete',
  deleteAll: 'Delete With Connections',
  deleteColumn: 'Delete Column',
  deleteRow: 'Delete Row',
  diagram: 'Diagram',
  diamond: 'Diamond',
  diamondThin: 'Diamond (thin)',
  direction: 'Direction',
  distribute: 'Distribute',
  divider: 'Divider',
  documentProperties: 'Document Properties',
  dotted: 'Dotted',
  drawing: 'Drawing{1}',
  drawingEmpty: 'Drawing is empty',
  drawingTooLarge: 'Drawing is too large',
  duplicate: 'Duplicate',
  duplicateIt: 'Duplicate {1}',
  east: 'East',
  edit: 'Edit',
  editData: 'Edit Data',
  editDiagram: 'Edit Diagram',
  editImage: 'Edit Image',
  editLink: 'Edit Link',
  editStyle: 'Edit Style',
  editTooltip: 'Edit Tooltip',
  enterGroup: 'Enter Group',
  enterValue: 'Enter Value',
  enterName: 'Enter Name',
  enterNamesEmailAddresses: 'Enter names or email addresses...',
  enterPropertyName: 'Enter Property Name',
  entityRelation: 'Entity Relation',
  exitGroup: 'Exit Group',
  expand: 'Expand',
  export: 'Export',
  exportImport: 'Export/Import',
  extras: 'Extras',
  fileNotFound: 'File not found',
  filename: 'Filename',
  fill: 'Fill',
  fillColor: 'Fill Color',
  fitPageWidth: 'Page Width',
  fitWindow: 'Fit Window',
  flip: 'Flip',
  flipH: 'Flip Horizontal',
  flipV: 'Flip Vertical',
  font: 'Font',
  fontFamily: 'Font Family',
  fontColor: 'Font Color',
  fontSize: 'Font Size',
  format: 'Format',
  cannotFindPublicDiagram:
    'The diagram you are trying to copy is no longer available. <br> Please check with its owner.',
  formatPdf: 'PDF',
  formatPng: 'PNG',
  formatGif: 'GIF',
  formatJpg: 'JPEG',
  formatSvg: 'SVG',
  formatXml: 'XML',
  formatted: 'Formatted',
  formattedText: 'Formatted Text',
  gap: 'Gap',
  general: 'General',
  glass: 'Glass',
  global: 'Global',
  gradient: 'Gradient',
  gradientColor: 'Color',
  grid: 'Grid',
  gridSize: 'Grid Size',
  group: 'Group',
  guides: 'Guides',
  heading: 'Heading',
  height: 'Height',
  hide: 'Hide',
  hideIt: 'Hide {1}',
  hidden: 'Hidden',
  home: 'Home',
  horizontal: 'Horizontal',
  horizontalFlow: 'Horizontal Flow',
  horizontalTree: 'Horizontal Tree',
  html: 'HTML',
  image: 'Image',
  images: 'Images',
  increaseIndent: 'Increase Indent',
  insert: 'Insert',
  insertColumnBefore: 'Insert Column Left',
  insertColumnAfter: 'Insert Column Right',
  insertHorizontalRule: 'Insert Horizontal Rule',
  insertImage: 'Insert Image',
  insertLink: 'Insert Link',
  insertRowBefore: 'Insert Row Above',
  insertRowAfter: 'Insert Row Below',
  invalidName: 'Invalid name',
  invalidOrMissingFile: 'Invalid or missing file',
  invitePeople: 'Invite people',
  isometric: 'Isometric',
  italic: 'Italic',
  layers: 'Layers',
  landscape: 'Landscape',
  laneColor: 'Lanecolor',
  layout: 'Layout',
  left: 'Left',
  leftAlign: 'Left Align',
  leftToRight: 'Left to Right',
  limitReached: 'Limit reached',
  line: 'Line',
  link: 'Link',
  lineJumps: 'Line jumps',
  lineend: 'Line End',
  lineheight: 'Line Height',
  linestart: 'Line Start',
  linewidth: 'Linewidth',
  snippetCopied: 'Snippet copied to clipboard',
  linkCopied: 'Link copied to clipboard',
  linkSharingTitle: 'Link to share',
  loading: 'Loading',
  lockUnlock: 'Lock/Unlock',
  libraryAsGroups: 'Library as groups',
  manual: 'Manual',
  middle: 'Middle',
  misc: 'Misc',
  more: 'More',
  moreResults: 'More Results',
  move: 'Move',
  moveSelectionTo: 'Move Selection to {1}',
  navigation: 'Navigation',
  new: 'New',
  noColor: 'No Color',
  noFiles: 'No files',
  noMoreResults: 'No more results',
  noResultsFound: 'No results found',
  noResultsInCategory: 'No results found in this category',
  noQuery: 'Enter a search term to get some results', //todo: maybe change this
  none: 'none',
  noResultsFor: "No results for '{1}'",
  normal: 'Normal',
  north: 'North',
  numberedList: 'Numbered List',
  opacity: 'Opacity',
  open: 'Open',
  openArrow: 'Open Arrow',
  openFile: 'Open File',
  openLink: 'Open Link',
  openSupported: 'Supported format is .XML files saved from this software',
  openInNewWindow: 'Open in New Window',
  openInThisWindow: 'Open in this Window',
  options: 'Options',
  organic: 'Organic',
  orthogonal: 'Orthogonal',
  outline: 'Outline',
  oval: 'Oval',
  pages: 'Pages',
  pageView: 'Page View',
  pageScale: 'Page Scale',
  panTooltip: 'Space+Drag to Scroll',
  paperSize: 'Paper Size',
  paste: 'Paste',
  pasteHere: 'Paste Here',
  pattern: 'Pattern',
  perimeter: 'Perimeter',
  placeholders: 'Placeholders',
  plusTooltip:
    'Click to connect and clone (ctrl+click to clone, shift+click to connect). Drag to connect (ctrl+drag to clone).',
  portrait: 'Portrait',
  position: 'Position',
  posterPrint: 'Poster Print',
  preview: 'Preview',
  recipientWithoutAccount: "recipient doesn't have a Machinations account",
  recipientsWithoutAccounts: "recipients don't have Machinations accounts",
  radialTree: 'Radial Tree',
  redo: 'Redo',
  removeFormat: 'Clear Formatting',
  removeFromGroup: 'Remove from Group',
  removeIt: 'Remove {1}',
  removeWaypoint: 'Remove Waypoint',
  renameIt: 'Rename {1}',
  replace: 'Replace',
  replaceIt: '{1} already exists. Do you want to replace it?',
  replaceExistingDrawing: 'Replace existing drawing',
  reset: 'Reset',
  resetView: 'Reset View',
  reverse: 'Reverse',
  right: 'Right',
  rightAlign: 'Right Align',
  rightToLeft: 'Right to Left',
  rotate: 'Rotate',
  rotateTooltip: 'Click and drag to rotate, click to turn by 90 degrees',
  rotation: 'Rotation',
  rounded: 'Rounded',
  save: 'Save',
  saved: 'Saved',
  continue: 'Continue',
  scrollbars: 'Scrollbars',
  search: 'Search',
  searchShapes: 'Search Shapes',
  seeMore: 'See more',
  selectAll: 'Select All',
  selectFont: 'Select a Font',
  selectNone: 'Select None',
  send: 'Send',
  sendCopy: 'Send a copy',
  setAsDefaultStyle: 'Set as Default Style',
  shadow: 'Shadow',
  shape: 'Shape',
  sharp: 'Sharp',
  sidebarTooltip:
    'Click to expand. Drag and drop shapes into the diagram. Shift+click to change selection. Alt+click to insert and connect.',
  simple: 'Simple',
  simpleArrow: 'Simple Arrow',
  size: 'Size',
  tokenCopied: 'Token copied to clipboard',
  solid: 'Solid',
  sourceSpacing: 'Source Spacing',
  south: 'South',
  spacing: 'Spacing',
  straight: 'Straight',
  strokeColor: 'Line Color',
  style: 'Style',
  subscript: 'Subscript',
  superscript: 'Superscript',
  table: 'Table',
  targetSpacing: 'Target Spacing',
  text: 'Text',
  textAlignment: 'Text Alignment',
  textOpacity: 'Text Opacity',
  toBack: 'To Back',
  toFront: 'To Front',
  tooltips: 'Tooltips',
  top: 'Top',
  topAlign: 'Top Align',
  topLeft: 'Top Left',
  topRight: 'Top Right',
  transparent: 'Transparent',
  turn: 'Rotate 90°',
  uml: 'UML',
  underline: 'Underline',
  undo: 'Undo',
  ungroup: 'Ungroup',
  url: 'URL',
  untitledLayer: 'Untitled Layer',
  vertical: 'Vertical',
  verticalFlow: 'Vertical Flow',
  verticalTree: 'Vertical Tree',
  view: 'View',
  waypoints: 'Waypoints',
  west: 'West',
  width: 'Width',
  wordWrap: 'Word Wrap',
  writingDirection: 'Writing Direction',
  zoom: 'Zoom',
  zoomIn: 'Zoom In',
  zoomOut: 'Zoom Out',
  renameGraphTitle: 'Rename',
  untitledGraphTitle: 'Untitled',
  editGraphTitle: 'Edit graph title',
  editGraphicon: 'Edit graph icon',
  orchestrator: 'Run',
  orchestratorRun: 'Run',
  orchestratorStep: 'Step',
  orchestratorPause: 'Pause',
  orchestratorStop: 'Stop',
  machinationsPanel: 'Functional',
  orchestratorQuickRun: 'Quick Run Start',
  orchestratorQuickPause: 'Quick Run Pause',
  orchestratorMultipleRun: 'Multiple Run Start',
  orchestratorMultiplePause: 'Multiple Run Pause',
  reopen: 'Reopen Last Edited',
  number_of_runs: 'Multiple Runs',
  number_of_runs_batch: 'batch',
  number_of_runs_total: 'total',
  logout: 'Logout',
  accountDetails: 'Account details',
  settingsMembers: 'Members',
  billingHistoryTab: 'Billing History',
  upgradeDetails: 'Upgrade details',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  reload: 'Reload',
  loadDiagramVersion:
    "Load diagram ver. <span class='m-diagram-version-name'>{version}</span>",
  loadDiagram: 'Load diagram',
  submit: 'Submit',
  saveBeforeQuickRun: 'Save your machinations first before quick run.',
  addToChartBeforeMultipleRun:
    "Please plot at least one element on the chart <br /> by right clicking a Node -> 'Show in chart'.",
  insertEmailAddress: 'Please insert a valid email address.',
  libraryItemName: 'Library item name',
  libraryInstruction:
    'Select items to delete, to set their visibility, or drag & drop them to change their position.',
  setVisible: 'Set Visible',
  setHidden: 'Set Hidden',
  notificationLimit: 'Notifications limit',
  notificationInfo: 'Info',
  notificationWarnings: 'Warnings',
  notificationErrors: 'Errors',
  noNotification: 'No notification received.',
  notifications: 'Notifications',
  youDontHaveNotifications: "You don't have any notifications!",
  youDontHaveNotificationsType:
    "You don't have any {notificationType} notifications!",
  noNewNotification: 'No new notification',
  clearAllTypeNotifications: 'Clear {notificationType} notifications',
  editingInViewShare:
    "View Only: Please <a href='javascript: void(0)' class='toast-link-make-a-copy'>Make a Copy</a> to enable saving.",
  alreadyPaidAccount:
    'You already have an active subscription for {1}.<br>Access your Payment Plan settings to update preferences.',
  memberOfTeam:
    'You are a member of {1} team. <br> To update your payment plan, please contact your team administrator.',
  goToSettings: 'Open Payment Plan',
  yourBillingCycle: 'Your billing cycle',
  changeCoverPhoto: 'Change Cover Photo',
  privateCommunityProfile: 'Private Community Profile',
  generateRenew: 'Generate / Renew',
  pro: 'PRO',
  switchedToPublicProfile:
    "Congrats! All your public diagrams and bio are now featured under <a href='https://machinations.io/community/{username}/' target='_blank'>your Machinations Community profile</a>.",
  switchedToPrivateProfile:
    'Your profile is now Private. Your public diagrams and profile info are hidden from your community page.',
  privateCommunityProfileProIndicatorMessage:
    "All free accounts are public. To go private, choose one of our <a href='javascript:void(0);' class='settings toast-link-upgrade'>Paid Plans</a>.",
  diagramNotFound: 'Diagram not found.',
  manageDiagram: 'Manage diagram',
  openMenu: 'Open menu',
  menu: 'Menu',
  openATemplate: 'Open a template',
  openATutorial: 'Open a tutorial',
  pageSetup: 'Page setup',
  accountSettings: 'Account Settings',
  aboutMachinations: 'About Machinations',
  aboutMachinationsContent:
    "      Machinations combines the power of visualising game loops with that of dynamic systems simulation.       Map your game into an interactive diagram, set parameters that define the relationship between       your game's entities, and visualise the way in which these systems work.       Based on that, you can simulate different outcomes, plot results and balance your game's economy.",
  onlyMe: 'Only Me',
  sharedWithMyTeam: 'Shared with my team',
  sharedWithSpecificPeople: 'Shared with specific people',
  sharedWithMyTeamAndSpecificPeople: 'Shared with my team and specific people',
  sharedWithMyTeamSpecificPeopleAndLink:
    'Shared with my team, specific people and link',
  sharedWithMyTeamAndLink: 'Shared with my team and anyone with the link',
  sharedWithSpecificPeopleAndLink: 'Shared with specific people and link',
  sharedWithAnyoneWithTheLink: 'Anyone with the link',
  publicOnCommunityAndTeamAndSpecific:
    'Public on community and shared with my team and specific people',
  publicOnCommunityAndTeam: 'Public on community and shared with my team',
  publicOnCommunityAndSpecific:
    'Public on community and shared with specific people',
  publicOnCommunity: 'Public on Community',
  teamOrInvitedUsers: 'Team or invited users',
  changeDiagramPrivacy: 'Change diagram privacy to: ',
  followingChangesWillBeApplied: 'The following changes will be applied:',
  diagramBecomesPrivate: 'Your diagram will become private.',
  diagramBecomesPublic: 'Your diagram will become public.',
  diagramBecomesPublicOnCommunity:
    'Your diagram will become public on the Machinations Community.',
  removeTeamAccess: 'Team members access will be revoked.',
  grantTeamAccess: 'Team members will have edit permissions.',
  grantTeamAccessView: 'Team members will have view permissions.',
  removeShareAccess:
    'Everyone else’s access to it will be revoked, including that of team members.',
  accountBecomesPublicOnCommunity: 'You will have a public community profile.',
  accountBecomesPrivate: 'Your profile will become private.',
  individualUserShareAccessChanged:
    'Change permission to {permission} for {email}.',
  privateDiagram: 'Private Diagram',
  publicDiagram: 'Public Diagram',
  diagramPrivacyChangedTo: 'Diagram privacy changed to {privacyOption}.',
  emptyDiagramPrivacyChange: 'Save the diagram before changing its privacy.',
  goToSettingsToChangeAccountPrivacy:
    "This sharing option requires a private account. Go to <a href='javascript: void(0)' class='toast-link-account'> Account details </a> to change your account’s privacy settings.",
  allPublicDiagramsVisibleOnCommunity:
    'All other diagrams with a public link will show up under your community profile.',
  readMoreAboutPrivacyPolicy:
    "<a href='https://machinations.io/terms-and-conditions/#privacy-policy' target='_blank'>Read more about our community profiles privacy policy.</a>",
  vertexDisabledBecauseInput: 'Missing input Resource Connection or trigger',
  vertexDisabledBecauseNoConnection: 'Missing active Connections',
  edgeDisabledBecauseSourceMissing: 'Missing origin Node',
  edgeDisabledBecauseInfiniteLoop:
    'Connection disabled due to infinite looping resources',
  componentDisabledBecauseConflictOnActivation:
    'Component disabled due to conflict on activation',
  edgeDisabledBecauseTargetMissing: 'Missing target Node',
  edgeDisabledBecauseSourceIsNotMachinations:
    "Connection's origin must be a Node",
  edgeDisabledBecauseTargetIsNotMachinations:
    "Connection's target must be a Node",
  sourceDisabledBecauseStructure: 'Missing active output Resource Connection',
  registerDisabledBecauseStructureInteractive:
    'Missing active output State Connection',
  registerDisabledBecauseStructurePassive:
    'Missing active input State Connection',
  delayDisabledBecauseInput: 'Missing active input Resource Connection',
  delayDisabledBecauseOutput: 'Missing active output Resource Connection',
  delayDisabledBecauseMoreOutput:
    'Delays cannot have more than one active output Resource Connection',
  drainDisabledBecauseStructure: 'Missing active input Resource Connection',
  traderDisabledBecauseLessColors:
    'Traders require exactly two different color coded Resources as input and output. Make sure Color Coding is ticked.',
  traderDisabledBecauseMoreColors:
    'Traders require exactly two pairs of active Resource Connections differentiated through colour coding. Make sure Color Coding is ticked.',
  traderDisabledBecauseColors:
    'Traders require exactly two pairs of active Resource Connections differentiated through colour coding. Make sure Color Coding is ticked.',
  traderDisabledBecauseInputHasNoColors:
    'Traders require all active input Resource Connections to be colour coded, even if one of the colours is the default Black. Make sure Color Coding is ticked.',
  traderDisabledBecauseOutputHasNoColors:
    'Traders require all active output Resource Connections to be colour coded, even if one of the colours is the default Black. Make sure Color Coding is ticked.',
  converterDisabledBecauseStructure:
    'Converters require at least one active input Resource Connection and exactly one active output Resource Connection.',
  gateDisabledBecauseHasNoOutput:
    'Missing at least one active output Resource Connection',
  gateDisabledBecauseHasNoInput:
    'Missing at least one active input Resource Connection',
  gateDisabledBecauseHasNoOutputState:
    'Missing at least one output State Connection',
  gateDisabledBecauseOutputLabel:
    'Gates require all their active output Resource Connections to be of the same type: conditional or random',
  gateDisabledBecauseOutputStateLabel:
    'Gates require all their active output State Connections to be of the same type: conditional or random',
  poolDisabledBecauseStructure:
    'Pool missing Resources, active input Resource Connections or active input Node Modifiers',
  nodeDisabledByActivator: 'Node disabled by an activator',
  nodeDisabledByAllActivators: 'Node disabled by all activators',
  endConditionDisabledBecauseInput: 'Missing input State Connection',
  nodeEnabledByActivator: 'Node enabled by an activator',
  activatorEnabledBecauseValue:
    'Activator enabled due to logical evaluation of its Label expression',
  activatorDisabledBecauseValue:
    'Activator disabled due to logical evaluation of its Label expression',
  triggerDsabledBecauseTarget:
    'State Connection disabled because is labeled as trigger and can not target any type of  another connection',
  nodeEnabled: 'Node enabled',
  edgeEnabled: 'Resource Connection enabled',
  edgeStateEnabled: 'State Connection enabled',
  edgeDisabledBecauseSourceOrTarget:
    'Connection disabled due to missing or disabled origins or targets',
  edgeDisabledBecauseRegisterTarget:
    'Register output State Connection cannot target to a',
  edgeDisabledBecauseSourceDisabled:
    'Connection disabled because of disabled origin',
  edgeDisabledBecauseFormula: 'Connection disabled because of invalid formula',
  edgeDisabledBecauseSource:
    'Resource Connection disabled because of incompatible origin',
  edgeDisabledBecauseTarget:
    'Resource Connection disabled because of incompatible target',
  edgeDisabledBecauseInterval:
    'Connection disabled because of invalid interval',
  edgeDisabledBecauseFormulaOrInterval:
    'Connection disabled because of invalid formula or interval: {error}',
  registerDisabledBecauseFormula:
    'Register disabled because of invalid formula: {error}',
  edgeDisabledBecauseTargetHasNoStep:
    'Disabled because the target has no Time Interval property',
  vertexDisabledBecause: 'Node disabled because',
  edgeDisabledBecause: 'Connection disabled because',
  nodeDisabledBecauseInfiniteLoop:
    'Node disabled due to infinite loop detected',
  isDisabled: 'is disabled',
  templateItemName: 'Template Name',
  Settings: 'Settings',
  exportToGoogleSheets: 'Export to Google Drive',
  importFromGoogleSheets: 'Import from Google Drive',
  exportingToGoogleSheets: 'Exporting document to Google Drive...',
  importingFromGoogleSheets: 'importing content from Google Drive...',
  SyncWithGoogleOption: 'Exported to Google Drive',
  ImportFile: 'Importing from Google Drive',
  ExportFile: 'Exporting to Google Drive',
  googleDriveLoadingError:
    'Cannot access Google Drive. Please try again later.',
  googleDriveMovingError:
    "Document saved on Google Drive's root. Error reported on moving document to the selected folder.",
  googleDriveMovingSuccess:
    'Document saved on Google Drive in the selected folder.',
  noIdentifiedComponent: 'Has no idenitified component',
  popupBlocked:
    'Your pop-up blocker is preventing you from proceeding.<br>Disable it for Machinations and try again.',
  orchestratorFlowError: 'Something went wrong with the orchestrator flow.',
  orchestratorStarted: 'Start execution of',
  orchestratorStopped_1: 'End the execution of',
  orchestratorStopped_2: 'after',
  orchestratorStopped_3: 'steps',
  orchestratorStoppedByEndCondition: 'by EndCondition',
  orchestratorStoppedByUser: 'by user',
  orchestratorStoppedByTimeStepLimit: 'by time steps limit',
  timeStepsPropertyLimit: 'Time Steps limited to',
  libraryItemLimit: 'Items in library limited to',
  numberOfComponentsExceeded:
    "You've reached the maximum number of components included in your current subscription plan. Please <a href='javascript: void(0)' class='toast-link-upgrade'>upgrade</a>. Questions on Beta Pricing? <a href='Intercom('show');' class='toast-link-chat intercom-open'>Chat with us</a>",
  Invitations: 'Invitations',
  teamInvite: 'Team invite',
  invitationAccepted: 'Invitation accepted',
  invitationDeclined: 'Invitation declined',
  invitationSent: 'Invitation sent to {inviteeEmail}',
  tags: 'Tags',
  deleteTag: 'Delete tag',
  createNewTag: 'create new',
  tagColor: 'Text Color',
  tagBackgroundColor: 'Background Color',
  tagLimitMessage: 'Max number of tags reached',
  tagCreateMessage: 'Filter to create new tag',
  tagSelectError: 'Can not select/deselect tags. Please save file first!',
  useSearchToFindTags: 'You can use the search bar to find other tags',
  teamInviteBy: 'Team invite from',
  memberLeave: 'Leave',
  memberLeaveConfirm: 'Are you sure that you want to leave this group?',
  memberDelete: 'Delete Member',
  memberDeleteConfirm:
    'Are you sure that you want to delete member from this group?',
  manageConflictDialogTitle: 'Merge conflicts!',
  manageConflictDialogText1:
    "You've made changes on components already modified by another user.",
  manageConflictDialogText2:
    'By pressing CONFIRM you will force saving your version or you may cancel (X) and reload the document or save a copy of it.',
  manageConflictDialogButton: 'CONFIRM',
  manageConflictDialogDeleted: 'Deleted cell.',
  yourAccount: 'Your Account',
  pricingPlans: 'Pricing plans<br>all plans include a 14 day FREE trial',
  pricingPlansDetails: 'Details',
  pricingPlansBilling: 'Usage & Billing',
  pricingPlansCheckout: 'Checkout',
  addOrRemoveUsers: 'You’re about to {1} your account.',
  addUsersTrialPeriod:
    'You will not be charged for the remainder of your free trial, until {1}. From then onwards, we will charge you {2} {3}.',
  removeUsersTrialPeriod:
    'This change will be applied immediately after you click on Remove Users below.',
  addUsersPaidPeriod:
    'You will be charged {1}€ to accommodate this change. After your next billing date your subscription will amount to {2} {3}.',
  removeUsersPaidPeriod:
    'This change will apply beginning with your next billing cycle on {1}.',
  beforeAddOrRemoveUsers:
    'Balance your Resources to accommodate any stage of your team’s evolution. Add or remove users to accommodate any change, any time.',
  removeUsersWhenAllAssigned:
    "All users are assigned. You'll need to remove  <a href='javascript:void(0);' class='white-link' id='paymentPlanToMembers'>members</a>  before proceeding.",
  addUsersTrialPeriodButton: 'Add {1}',
  removeUsersButton: 'Remove {1}',
  payNow: 'Pay Now ',
  addOrRemoveUsersButtonIdle: 'Add or Remove Users',
  viewHistoryMenu: 'See version history',
  viewHistoryTitle: 'Version history',
  versionHistoryFirstVersion: 'Current version',
  versionHistoryLatestVersion: 'Latest version',
  reloadLatestVersion: 'Reload latest version',
  versionHistoryLastVersion: 'Initial version',
  viewHistoryRestoreButton: 'Restore this version',
  viewHistoryRestoreDialogText:
    'Restore this version? <br> Your current diagram will revert to the version from <br/> {1}',
  viewHistoryRestoreDialogButton: 'Restore',
  viewHistoryRevertText:
    '{1} has reverted this diagram to its version from {2}.',
  viewHistoryRevertButton: 'Reload',
  billingHistory: 'Bills',
  canNotModifySeats:
    'The number of users could not modified because you have one payment pending!',
  members: 'Your team',
  confirmDeleteMembersButton: 'Remove',
  removeTeamMember: 'Remove team member?',
  confirmDeleteMembers:
    '{memberName} will no longer have access to team diagrams and will be permanently deleted from your team.',
  confirmBlockMembersButton: 'Block',
  confirmBlockMembers:
    'Block team member? <br><br> {1} will no longer have access to team diagrams. <br><br> This action is reversible in the Members tab.',
  confirmUnblockMembersButton: 'Activate',
  confirmUnblockMembers: 'Member will be unblocked',
  confirmCancelSubscriptionButton: 'Confirm',
  confirmCancelSubscription: 'Confirm subscription cancellation',
  confirmCancelSubscriptionType: 'Cancel immediately',
  confirmCancelSubscriptionMessage1:
    'Your subscription will end on {subscriptionEndDate}.',
  confirmCancelSubscriptionMessage2: 'Cancellation takes effect immediately.',
  confirmCancelSubscriptionMessage:
    "Your team members' accounts will be downgraded to Public, and they will lose access to the team folder.",
  confirmCancelCancelSubscription:
    'By confirming, you will undo your subscription cancellation.',
  canNotModifySeets:
    "The number of users could not be modified. Please write us at <a href='mailto:support@machinations.io'>support@machinations.io</a>",
  taxID: 'Tax ID',
  taxIDRegistrationNumber: 'Tax ID / Reg.No.',
  startTypingToSearchTemplate: 'Start typing to search template',
  thisDiagramHasNoDescription: 'This diagram has no description',
  templateLink: 'Template Link',
  noLinkGeneratedYet: 'No link generated yet',
  generateLink: 'Generate link',
  diagramDoesntHaveTags: "The diagram doesn't have any tags",
  templateLinkCopied: 'Template link successfully copied ',
  overwriteTemplate: 'Overwrite Template',
  saveNewTemplate: 'Save New Template',
  loginTitle: 'Machinations Login',
  loginBestExperience: 'For the best experience, please consider using',
  loginBestExperienceSubtitle: 'We’ll have support for all browsers soon!',
  loginHeading: 'Log In to Machinations',
  loginNoAccount: "Don't have an account?",
  loginLinkToRegister: 'Create Free Public Account',
  loginWithGoogle: 'Log In with Google',
  loginWithEmail: 'Log In',
  loginWorks: 'Works in',
  loginGoogleChrome: 'Google Chrome',
  loginForgotPassword: 'Forgot password?',
  loginEmailAddress: 'Email Address',
  loginPassword: 'Password',
  loginInvalidEmail: 'You must enter a valid email address!',
  loginInsecurePassword: 'Password must contain at least 6 characters!',
  loginResetTokenExpired: 'Your token has expired!',
  loginVerifyEmailTokenExpired: 'Your verification token has expired!',
  loginVerifyEmailDBError: 'Can not query database. Please try again later!',
  registerTitle: 'Register to Machinations',
  registerHeading: 'Create Free Public Account',
  registerExistingAccount: 'Already have an account?',
  registerLegalNotice1:
    'By creating this free account, your profile and all user content will be public and available to anyone in our community (see ',
  registerLegalNotice2: 'section IV – Creating a user account',
  registerLegalNotice2URL:
    'https://machinations.io/terms-and-conditions/#creating-a-user-account',
  registerLegalNotice3:
    ' in our Terms of Service for more details). If you want to keep your diagrams private, you need to upgrade to a paid account. Also, according to our ',
  registerLegalNotice4: 'Tier Eligibility',
  registerLegalNotice4URL:
    'https://machinations.io/terms-and-conditions/#tiers-and-eligibility',
  registerLegalNotice5:
    ' criteria (personal, non-profit, academia), please make sure you are eligible for a free account. Otherwise, upgrade to a paid account.',
  registerWithGoogle: 'Register with Google',
  registerWithEmail: 'Register',
  registerConfirmPassword: 'Confirm Password',
  registerLinkToLogin: 'Log In',
  registerUnmatchedPasswords: 'The two passwords entered do not match!',
  registerCheckCaptcha: "Oops! Please check I'm not a robot.",
  welcomeEmailSubject: 'Welcome to Machinations',
  placeholderPleaseType: 'Please Type',
  recoveryTitle: 'Machinations Password Recovery',
  recoveryHeading:
    "Please enter your Email Address and we'll send you instructions for resetting your password.",
  recoverySubheading: '',
  recoveryButton: 'Send',
  recoveryBackToLogIn: 'Back to Log In',
  recoveryEmailSuccess: 'We sent you an email with further instructions.',
  recoveryEmailError: 'Error when sending the confirmation link.',
  recoveryEmailSubject: 'Verify your email address',
  recoveryResetTitle: 'Machinations Password Reset',
  recoveryResetHeading: 'Reset Password for',
  recoveryResetSubheading:
    "Please enter your Email Address and we'll send you instructions for resetting your password",
  recoveryResetPassword: 'New Password',
  recoveryResetConfirmPassword: 'Confirm New Password',
  recoveryResetEmailError: 'Email input should be readonly!',
  recoveryResetWrongEmail:
    'Please enter the correct email address for your account.',
  recoveryResetSuccess:
    'Your password has been updated. You will be redirected soon.',
  recoveryResetError: 'An error has occured during the password update.',
  recoveryResetErrorUpdating:
    'An error has occured while updating the password.',
  recoveryResetErrorRequest: 'Please fill the form correctly.',
  worksBestIn: 'Works best in',
  youAreInGoodCompany: "You're in good company",
  'design&BalanceGameSystems': 'Design & Balance Game Systems',
  goToAuthentication: 'Go to authentication',
  recoveryPasswordEmailFound:
    "Success! We've sent you an email with further instructions. You will be redirected soon...",
  passwordChangedSuccessfully: 'Your Password was changed successfully',
  successRegistration1:
    'Thank you for registering! A confirmation email has been sent to ',
  successRegistration2: ' Please use it to activate your account',
  registerFooter:
    "By registering you agree to Machinations’<br> <a class=m-link href='https://machinations.io/terms-and-conditions/' target='_blank'>Terms</a> and <a class='m-link' href='https://machinations.io/terms-and-conditions/#privacy-policy target='_blank''>Privacy Policy</a>",
  updateRequired: 'Update required',
  newAccount: 'New Account',
  createAccount: 'Create Account',
  logInWithADifferentAccount: 'Log in with a different account',
  wrongPassword: 'Oops! Wrong email address or password',
  forgotPasswordError: 'There is no user registered with this email address',
  resetPasswordDifferentThanConfirmPassword:
    'New password is different than confirm password!',
  changePasswordError:
    "Your password could not be changed. Please try again. If the error persists, don’t hesitate to <a href='mailto:support@machinations.io?subject=Password change error'>contact us</a>.",
  registerGoogleFailed:
    "An error occurred while attempting to create your account. Please try again. If the error persists, don’t hesitate to <a href='mailto:support@machinations.io?subject=Create account error'>contact us</a>.",
  loginConfirmEmail1: 'A confirmation email has been sent to',
  loginConfirmEmail2: 'Please use it to activate your account.',
  backToLogin: 'Back to Login',
  recaptchaError: "Oops! Please check I'm not a robot.",
  congratsJustUnlocked: 'You just unlocked',
  congratsUpgradeToIndieTitle: 'Well Played!',
  congratsUpgradeToStudioTitle: 'Good Game!',
  congratsUpgradeToAAATitle: 'Good Game!',
  congratsUpgradeToIndieDescription:
    'You leveled up your account to Essential!',
  congratsUpgradeToStudioDescription:
    "Let's fire up them servers with unlimited simulations!",
  congratsUpgradeToAAADescription:
    "Let's fire up them servers with unlimited simulations!",
  premiumFeatureTitleIndie1: '3,000 Components / Team',
  premiumFeatureDescriptionIndie1:
    'Build more complex diagrams & systems, together with your team',
  premiumFeatureTitleIndie2: '1,000 Run Steps',
  premiumFeatureDescriptionIndie2:
    'Simulate longer player journeys to make sure your systems are fail-proof.',
  premiumFeatureTitleIndie3: 'Team Management',
  premiumFeatureDescriptionIndie3:
    "Invite your teammates. Edit & manage diagrams in co-op real-time, like it's the 21st century :)",
  premiumFeatureTitleStudio1: '10,000 Components / Team',
  premiumFeatureDescriptionStudio1:
    'Build super-complex diagrams and run unlimited simulations to fail-proof your systems.',
  premiumFeatureTitleStudio2: 'Priority Support',
  premiumFeatureDescriptionStudio2:
    'Our dedicated support team will now get back to you within 1 business day.',
  premiumFeatureTitleStudio3: 'Team Management',
  premiumFeatureDescriptionStudio3:
    "Invite your teammates. Edit & manage diagrams in co-op real-time, like it's the 21st century :)",
  premiumFeatureTitleAAA1: 'Unlimited Components and Run Steps',
  premiumFeatureDescriptionAAA1:
    'Build super-complex diagrams and run unlimited simulations to fail-proof your systems.',
  premiumFeatureTitleAAA2: 'Priority Support',
  premiumFeatureDescriptionAAA2:
    'Our dedicated support team will now get back to you within 1 business day.',
  premiumFeatureTitleAAA3: 'Team Management',
  premiumFeatureDescriptionAAA3:
    "Invite your teammates. Edit & manage diagrams in co-op real-time, like it's the 21st century :)",
  comparePlansFree: 'Free Forever',
  comparePlansMonthlyBilling: 'Monthly / Team',
  comparePlansYearlyBilling: 'Monthly / Team paid annually',
  billingPeriod: 'Billing period',
  'cp-privateDiagrams':
    'The Public plan shares diagrams with the Community by default (no private diagrams). Paid plans keep diagrams private by default and have to ability to share them with the Machinations Community.',
  'cp-editors': 'Collaborative editors with real-time share and sync.',
  'cp-teamManagement': 'Team & members basic management.',
  'cp-liveEditing': 'Edit diagrams real-time co-op, à la Google Suite.',
  'cp-teamFolder':
    'Keep all your team diagrams in one collectively shared folder.',
  'cp-teamLibrary': 'Share library items with your team only.',
  'cp-versionHistory': 'Access and restore discrete historical diagram states.',
  'cp-components':
    '1 Component = 1 Node or Connection line. The quota represents the maximum number of Components placed on all diagrams in your account, or all diagrams in your team folder (for paid plans).',
  'cp-privateLibraryItems':
    'Maximum number of library items shared with your team members.',
  'cp-diagramTags': 'Maximum number of unique tags per account or team.',
  'cp-simulations':
    'Monthly allowance of Quick Plays (server side executions) per user or team.',
  'cp-runSteps': 'Maximum number of Play Steps (execution steps) per diagram.',
  'cp-advancedChartAnalytics':
    'Advanced chart statistics indicators: Mean, Median, Min/Max.',
  'cp-api':
    'API access via socket.io or the Unity Plugin (UP) to take parameters out of the diagram and sync them directly to your game objects.',
  'cp-unityPlugin':
    'Sync diagram parameters with your Unity scriptable objects real-time, over-the-air.',
  'cp-exportGoogleSheets': 'via Google Drive.',
  'cp-exportResults': 'via CSV.',
  'cp-communitySupport': 'Join our public Slack or Discord.',
  'cp-liveChatSupport':
    'Live chat Mon-Fri 8:00-20:00 Central European Time (CET) via the blue chat widget in the bottom left (Intercom).',
  'cp-prioritySupport': 'In business days.',
  'cp-onboarding':
    'A 1h kick-off call for the entire team to get up to speed with the tool and framework.',
  'cp-accountManager': 'Dedicated person to pick up any questions.',
  'cp-customerSuccess':
    'Assistance with any issues, including diagram crafting.',
  'cp-importGDD':
    'Custom work for translating your existing design into a single diagram for kick-off.',
  'cp-privateCloud': 'Via the AWS Marketplace server image.',
  'cp-sla': 'Custom contracts.',
  'cp-paymentByInvoice': 'Invoice and payment by bank transfer.',
  'cp-executionHistory':
    'Saving of historical execution results across sessions.',
  'cp-namedVersions': 'Custom naming of version history states.',
  'cp-customResources': 'Customise the currently color coded resources.',
  'cp-customVariables':
    'Define global variables, like the current legacy D, S, M, ST.',
  'cp-analyticsKPI':
    'Import aggregated KPIs from Unity Analytics, Game Analytics, Firebase or custom and sync them with diagram nodes or variables.',
  'cp-analyticsRawData':
    'Import raw data sets of player events and use them to play diagram executions (trigger diagram state changes via user event logs instead of random parameters).',
  'cp-autosolver': 'Auto-balance any parameter towards your desired goal',
  publicPlan: 'Public Plan',
  essentialPlan: 'Essential Plan',
  professionalPlan: 'Professional Plan',
  proPlans: 'Pro Plans',
  powerPlan: 'Power Plan',
  monthly: 'Monthly',
  yearly: 'Yearly',
  selectPlan: 'Continue',
  planDetails: 'Plan Details',
  billingDetails: 'Billing Details',
  checkout: 'Checkout',
  monthlyBilling: 'Monthly Billing',
  yearlyBilling: 'Yearly Billing',
  currentPlan: 'Current Plan',
  modifyPlan: 'Modify your plan',
  youAreOnA: 'You are on a',
  postalCode: 'Postal Code',
  city: 'City',
  country: 'Country',
  addCard: 'Add New Card',
  plan: 'Plan',
  product: 'Product',
  billingAddress: 'Billing address',
  invoicingEmail: 'Invoicing email',
  accountBalance: 'Account balance',
  invalidEmailFormat: 'Invalid email format',
  removeMembers: 'Remove Members',
  removeMember: 'Remove Member',
  contactUs: 'Contact Us',
  liveCollabText: 'Live collaborative editing',
  liveCollabToastText:
    "<a href='javascript: void(0)' class='toast-link-upgrade'>Upgrade</a> your account and share your file to collaborate in real time. <br> Or find out more about our <a href='javascript: void(0)' class='toast-link-pricing'>Pricing</a>",
  liveCollabShareTooltip: 'Free for all users until May 31st, 2020',
  duplicateWithConnections: 'Duplicate With Connections',
  pasteWithConnections: 'Paste With Connections',
  privateLibraryLimitationToastText:
    "Library items are limited to {limit} on {plan}. <a href='javascript: void(0)' class='toast-link-upgrade'>Upgrade</a> your plan to get unlimited slots.",
  teamLibraryLimitationToastText:
    "Team library items are limited to {limit} on {plan}. <a href='javascript: void(0)' class='toast-link-upgrade'>Upgrade</a> your plan to get unlimited slots.",
  teamLibraryUpgradeToastText:
    "Team libraries are available only for paid plans. To create your own team you need to <a href='javascript: void(0)' class='toast-link-upgrade'>upgrade your plan</a>.",
  runStepsLimitationToastText:
    "Public version is limited to {1} Time Steps. If you need more steps consider <a href='javascript: void(0)' class='toast-link-upgrade'>upgrading your plan.</a>",
  gDriveExportToastText:
    "<a href='javascript: void(0)' class='toast-link-upgrade'>Upgrade</a> your account to export diagrams to GDrive <br> or find out more about our <a href='javascript: void(0)' class='toast-link-pricing'>Pricing</a>",
  'setting.label.confirm': 'Confirm',
  changePassword: 'Change password',
  'setting.label.curentPlan': 'Curent Plan',
  'setting.label.upgrade.toIndie': 'Upgrade to ESSENTIAL',
  'setting.label.upgrade.toStudio': 'Upgrade to PROFESSIONAL',
  'setting.label.upgrade.toAAA': 'Upgrade to POWER',
  settingsUserKeyLabel: 'User Key',
  'setting.label.email': 'Email',
  settingsAddress: 'Address',
  settingsCompanyName: 'Company Name',
  settingsInvoiceDetailsLabel: 'Invoicing Details',
  settingDeleteAccount: 'Delete Account',
  'setting.confirm.change.psw.title': 'Change your password',
  'setting.confirm.change.psw.oldPsw': 'Old password',
  'setting.confirm.change.psw.notOldPsw': 'Logged in with Google account',
  'setting.confirm.change.psw.newPsw': 'New password',
  'setting.confirm.change.psw.confirmPsw': 'Confirm new password',
  'setting.confirm.change.psw.success':
    'Your password has been changed successfully! ',
  'setting.confirm.change.psw.longerThanChars':
    'Your password should be longer than {1} characters!',
  'setting.confirm.change.psw.notMatch':
    'The Password and Confirm password does not match!',
  'setting.confirm.change.psw.validation': 'Validation error! ',
  'setting.confirm.change.psw.DB.error':
    'System error during password change. Please contact support@machinations.io! ',
  'setting.confirm.change.userkey.title': 'Your user API key will be changed',
  'setting.confirm.change.userkey': 'If confirm, please update your content.',
  'setting.confirm.change.userkey.changed':
    'Your user key has been changed. Please update your content.',
  'setting.confirm.change.userkey.not.changed':
    'Your user key has NOT been changed! ',
  'diagram.panel.copy.token.title': 'Copy',
  'diagram.panel.generate.token.title': 'Generate Token',
  'diagram.panel.diagram.token.title': 'Diagram Token',
  'diagram.panel.diagram.token.not.id':
    'This is a new diagram. Please add some content first!',
  'diagram.panel.diagram.token.cannot.change':
    "Diagram Token can't be changed!",
  'diagram.panel.confirm.change.file.token.copied':
    'Diagram Token successfully copied for {1}',
  'diagram.panel.confirm.change.file.token.title':
    'Diagram Token will be changed!',
  'diagram.panel.confirm.change.file.token.msg':
    "Please update the communication parameter: <b>'diagramToken'</b>",
  topAlertCompleteBilling:
    'Please fill in your {link} to continue using your {plan} Plan',
  openSocialProfile: 'Open {socialNetwork} profile',
  enterSocialProfile: 'Enter {socialNetwork} profile link',
  viewHistoryMessageFree:
    "Changes older than {limit} days are available with our Essential, Professional and Power accounts. <a href='javascript:void(0);' class='settings'> Upgrade</a> to unlock your older version history",
  viewHistoryMessageIndie:
    "Changes older than {limit} days are available with our Professional and Power accounts. <a href='javascript:void(0);' class='settings'> Upgrade</a> to unlock your older version history",
  viewHistoryMessageStudio:
    "Changes older than {limit} days are available with our Power accounts. <a href='javascript:void(0);' class='settings'> Upgrade</a> to unlock all your version history",
  quote: 'Quote',
  getQuote: 'Get Quote',
  selectPlanAAALabel: 'Contact Us',
  planPriceFree: 'Free',
  changeProfilePrivacyDialogText:
    "By completing this action you agree to our new <b><a href='https://machinations.io/terms-and-conditions/' target='_blank'>Terms of Service</a></b>. <br> <br> Please note that this isn’t reversible while on a Free account. You are always welcome to upgrade to a paid plan, should you wish to go private.",
  changeProfilePrivacyDialogTextNoBr:
    "By completing this action you agree to our new <b><a href='https://machinations.io/terms-and-conditions/' target='_blank'>Terms of Service</a></b>. Please note that this isn’t reversible while on a Free account. You are always welcome to upgrade to a paid plan, should you wish to go private.",
  switchToPublicProfile: 'Switch to Public Profile',
  // Free product card
  paymentPlanFreeDescription: 'Individual account',
  paymentPlanFreePlanFeature1: 'Interactive diagrams',
  paymentPlanFreePlanFeature2: 'Low complexity diagrams <br/> ({value} components)',
  // Vault product card
  paymentPlanVaultDescription: 'Up to 3 Editors',
  paymentPlanVaultPlanFeature1: 'Privacy',
  paymentPlanVaultPlanFeature2: 'Team collaboration',
  paymentPlanVaultPlanFeature3: 'Live Chat Support',
  paymentPlanVaultPlanFeature4:
    "Medium complexity diagrams <br/> ({value} components)",

// Indie (= Essential) product card
  paymentPlanIndieDescription: 'Up to 3 Editors',
  paymentPlanIndiePlanFeature1: 'Low-scale Predictions',
  paymentPlanIndiePlanFeature2: 'Data in from Public GSheets',
  paymentPlanIndiePlanFeature3: 'Live Video Support',
  paymentPlanIndiePlanFeature4: "Basic Analytics",

// Studio (= Pro) product card
  paymentPlanStudioDescription: 'Up to 5 Editors',
  paymentPlanStudioPlanFeature1: 'Medium-scale Predictions',
  paymentPlanStudioPlanFeature2: 'Advanced Analytics',
  paymentPlanStudioPlanFeature3: 'Services',
  paymentPlanStudioPlanFeature4: 'High complexity diagrams <br/> ({value} components)',
  paymentPlanStudioPlanFeature4componentsOnly: '{value} components',
  paymentPlanStudioPlanFeature5: 'Data in from Private GSheets',
  paymentPlanStudioPlanFeature6: 'Import/Export to GSheets',

  // AAA (= Enterprise) product card

  paymentPlanAAADescription: 'Up to 10 Editors',
  paymentPlanAAAPlanFeature1: 'AI Balancer',
  paymentPlanAAAPlanFeature2: 'External Data Inputs',
  paymentPlanAAAPlanFeature3: 'SSO',
  paymentPlanAAAPlanFeature4: 'Advanced Services',
  paymentPlanAAAPlanFeature5: 'High-scale Predictions',
  paymentPlanAAAPlanFeature6: 'Exhaustive diagrams <br/> ({value} components)',

  paymentPlanCompareFeatures: 'Compare all features',
  paymentPlanAcademiaDescription: 'Unlimited Editors',
  comparePlans: 'Compare Plans',
  comparePlansTitle: 'Compare Plans in Detail',

  wordCapitalCollaboration: 'Collaboration',
  wordCapitalDesign: 'Design',
  wordCapitalPredict: 'Predict',
  wordCapitalIntegrations: 'Integrations',
  wordCapitalServices: 'Services',
  wordCapitalAiml: 'AI / ML',

  comparePlansDesignDescription:
    'Create playable diagrams for any system; efficiently iterate and communicate ideas',
  comparePlansPredictDescription:
    'Gain data-driven insights with Monte Carlo simulations. Test what-if scenarios and measure their impact in a safe, virtual environment',
  paidYearly: 'Paid Yearly (2 Months FREE)',
  paidMonthly: 'Paid Monthly',

  // collaboration

  planFeaturePrivateDiagrams: 'Private Diagrams',
  planFeatureEditors: 'Editors',
  planFeatureTeamManagement: 'Team Management',
  planFeatureCollaborativeEditing: 'Collaborative Editing',
  planFeatureTeamFolder: 'Team Folder',
  planFeatureTeamLibrary: 'Team Library',
  planFeatureNamedVersions: 'Named Versions',
  planFeatureVersionHistory: 'Version History',

  // design
  planFeatureComponents: 'Components',
  planFeatureAdditionalComponents: 'Additional Components',
  planFeatureLibraryItems: 'Library Items',
  planFeatureCustomVariables: 'Custom Variables, Randomness, Math',

  // predict
  planFeaturePlaySteps: 'Play Steps',
  planFeatureMonthlyBatchPlays: 'Monthly Plays for Predictions',
  planFeatureMonthlyBatchPlaysAddOn: 'Predictions Refill',
  planFeatureQuickPlaySpeed: 'Predictions Performance',
  planFeaturePlayHistory: 'Predictions History',
  planFeatureStatisticalIndicators: 'Statistical Indicators',
  planFeaturePlayAccuracyIndicator: 'Prediction Accuracy Indicators',
  planFeatureHistogram: 'Histogram',
  planFeatureChartComparison: 'Chart Comparison',

  // AI / ML
  planFeatureBalancer: 'AI Balancer',

  // integrations
  planFeatureExportPlayResults: 'Export Predictions Results',
  planFeatureExportGoogleSheets: 'Import/Export Diagrams to Google Sheets',
  planFeaturePublicGoogleSheets: 'Data Ingestion from Public Google Sheets',
  planFeaturePrivateGoogleSheets: 'Data Ingestion from Private Google Sheets',
  planFeatureCustomVariablesJson: 'Data Ingestion from JSON Import',
  planFeatureCustomVariablesMarket: 'Data Ingestion from Market Data feeds',
  planFeatureCustomVariablesData: 'Data Ingestion from Custom Data',

  planFeatureAPI: 'API',
  planFeatureUnityPlugin: 'Unity Plugin',
  planFeatureSSO: 'SSO Authentication',

  // services

  planFeatureCommunitySupport: 'Community Support',
  planFeatureLiveChatSupport: 'Live Chat Support',
  planFeaturePrioritySupport: 'Priority Support (Issue response time)',
  planFeatureAccountManager: 'Account Manager',
  planFeatureOnboarding: 'Onboarding',
  planFeatureTraining: 'Training',
  planFeatureCustomerSuccess: 'Dedicated Customer Success Manager',
  planFeatureImportMyGDD: 'Diagram Building',
  planFeaturePrivateCloud: 'Private Cloud',
  planFeatureSLA: 'SLA',
  planFeaturePaymentByInvoice: 'Payment by Invoice',

  planFeatureLabelAnnualPlanOnly: 'Annual Plan Only',

  planFeatureLabelNoOfDays: '{limit} Day | {limit} Days',
  planFeatureLabelNoOfWorkDays: '{limit} Work Day | {limit} Work Days',
  planFeatureLabelNoPerUser: '{limit} / User | {limit} / User',
  planFeatureLabelNoPerTeam: '{limit} / Team | {limit} / Team',
  planFeatureLabelProcessingThreads: '1x Single-thread | {limit}x Multi-thread',
  planFeatureLabelHoursPerMonth: '{limit}h / Month / Team',
  planFeatureLabelTrainingPrice: '${limit} / module',

  amountSaved: 'You save {amount} a year',
  possibleAmountSaved: 'Save {amount} when billed yearly',
  yearlyPerTeam: 'Yearly / Team',
  monthlyPerTeam: 'Monthly / Team',
  downgradeOrUpgrade: 'Downgrade or upgrade at any time.',
  changesApplyAtNextBilling: 'Changes applies only at the next billing.',
  billingSetTo: 'Billing cycle is set to {billingCycle}',
  setBillingCycle: 'Set to {billingCycle}',
  changeBillingType: 'Change billing type',
  save10: 'SAVE 10%',
  oldPlansBillingCycleChangeToast:
    "We’ve recently updated our plans’ currency to US Dollars. Due to this change, and the plan you’re currently on, your request cannot be performed automatically. Please contact us at <a href='mailto: contact@machinations.io'>contact@machinations.io</a>",
  billingCycleChangedSuccessfully:
    'Billing cycle successfully changed to <b>{billingCycle}</b>.',
  oldPlansResumeToast:
    'We’ve recently updated our plans’ currency to US Dollars. Due to this change, and the plan you’re currently on, your request cannot be performed automatically.',

  indieAgreementTitle: 'Company requirements for Indie Plan',
  contactSales: 'Contact sales',
  freeOneYear: 'for one year',
  startPlanBtn: 'Start Plan',
  downgradeToCommunity: 'Downgrade to Public',
  customNumberOf: 'Custom no. of ',
  dontShowAgain: "Don't show again",

  notEligibleForTrial:
    "We’ve noticed you previously had a trial with us. At the end of the onboarding, you will be redirected to Billing details, <br> to activate your payment plan selection. If you have questions, please <a href='mailto:sales@machinations.io' target='_blank'>contact sales</a>.",
  comparePlansNotEligibleForTrial:
    "We’ve noticed you previously had a trial with us. Therefore, you are not eligible for another. If you have questions, please <a href='javascript:void(0);'>contact us</a>.",

  settingsDialogComparePlansLink: 'Compare our plans in fine details',
  teamComponentsLimitExceeded:
    "{plan} is limited to {limit} components / {accType}. If you need more components, consider <a href='javascript: void(0)' class='toast-link-upgrade'>upgrading your plan.</a>",
  tagsLimitReachedMessage: '{limit} tags / {accType} limit reached',
  freeProductTagsLimitMessage:
    "{plan} version is limited to {limit} tags / {accType}. If you need more tags, consider <a href='javascript: void(0)' class='toast-link-upgrade'>upgrading your plan.</a>",
  noTagsToDisplay: 'No tags to display yet',
  upgradeToAddTags: 'Upgrade plan to add more tags',
  createTag: 'Create tag',
  tagAlreadyExists: 'Tag already exists',
  clickToCreateTag: 'Click to create new tag',
  removeAllTags: 'Remove all tags',
  manageTags: 'Manage Tags',
  clickOnTagToSetActive: 'Click on any tag to set it active',
  createFirstTag: 'Create your first tag',
  searchOrAddTags: 'Search or add tags',
  setAllTagsActive: 'Set all active',
  setAllTagsInctive: 'Set all inactive',
  noResults: 'No results',
  doubleClickToEdit: 'Double click on any tag to edit',
  clickToOpenTagList: 'Click to open tag list',
  ownerOf: 'Owner of',
  signOut: 'Sign Out',
  logOut: 'Log Out',
  libraryItems: 'Library Items',
  diagramTags: 'Diagram Tags',
  monthlyPlays: 'Monthly Plays',
  mCommunityProfile: 'My Community Profile',
  teamMembers: 'Team Members',
  startWith7PartTutorials: 'Start with our guided <br /> 7 part tutorials',
  learnFromDocumentation:
    'Learn anything you need <br /> from our documentation',
  academiaTeaserTitle: 'Find out about the <br /> Academia Program',
  whatsNew: "What's new",
  myMachinations: 'My Machinations',
  sharedWithMe: 'Shared with me',
  teamMachinations: 'Team',
  importFromGDrive: 'Import from G Drive',
  importFromGoogleDrive: 'Import from Google Drive',
  templates: 'Templates',
  tutorials: 'Tutorials',
  tests: 'Tests',
  settings: 'Settings',
  fileName: 'File name',
  components: 'Components',
  owner: 'Owner',
  lastModified: 'Last modified',
  newFile: 'New file',
  newDiagram: 'New diagram',
  deleteDiagram: 'Delete diagram',
  clickTagToSetAsFilter: 'Click on any tag to set it as filter',
  searchSomething: 'Search {target}',
  diagramsCount: '{count} diagrams',
  youHaveNoDiagramsMatching: 'No diagrams match your search criteria',
  getStarted: 'Get started',
  used: 'Used',
  upgradePlan: 'Upgrade plan',
  fullscreen: 'Fullscreen',
  exitFullscreen: 'Exit fullscreen',
  googleDrive: 'Google Drive',
  viewer: 'Viewer',
  editor: 'Editor',
  noAccess: 'No access',
  remove: 'Remove',
  emailCommaSeparated: 'Email, comma separated',
  addEmailAddress: 'Add email address',
  shareWithPeople: 'Share with people',
  shareWithLink: 'Share with link',
  manageRights: 'Manage rights',
  snippetCode: 'Snippet code',
  invalidEmail: 'Invalid email',
  invalidWorkEmail: 'Invalid work email',
  manageTeam: 'Manage Team',
  invited: 'Invited',
  membersOfTeam: 'Members of {teamName}',
  memberOfTeamName: 'Member of {teamName}',
  anyoneFromTheTeam: 'Anyone from the team',
  includeMessage: 'Include a message',
  message: 'Message',
  sharingWithNPeople: 'You’ll share this with {n} people',
  diagramToken: 'Diagram token',
  copyCode: 'Copy code',
  copyToken: 'Copy token',
  yourTeam: 'Your team',
  anyoneFromTheTeamCanEdit: 'Anyone from the team can edit',
  diagramAlreadySharedWith: 'Diagram is already shared with {email}',
  featureNotAvailableForFreePlans:
    'This feature is not available on Public plans',
  toCreateYourTeamNeedTo: 'To create your own team you need to',
  upgradeYourPlan: 'Upgrade your plan',
  diagramSharedSuccessfully: 'Diagram shared successfully',
  nameDiagramBeforeSharing: 'Name your file before sharing',
  giveNameBeforeSharing:
    "Give your untitled document a name before it's shared",
  pleaseChooseDifferentName: 'Please choose a different name',
  whatWeWillImport: "Here's what we'll import",
  cancelImport: 'Cancel import',
  overwriteDiagram: 'Overwrite diagram',
  importAsNew: 'Import in new diagram',
  lastModifiedAt: 'Updated {last_modified}',
  yourImportResultsFor: 'Your import results for “{importName}”',
  notAvailableInFreePlan:
    "This feature is not available for Public plans. Consider <a href='javascript: void(0)' class='toast-link-upgrade'>upgrading your plan.</a>",
  fileFormatNotRecognized: 'File format not recognized',
  componentName: 'Component name',
  qty: 'Qty',
  onlyOwnerCanChangeTeamRights: 'Only the owner can change the team rights',
  startYourJourney: 'Your Journey to design and balance any system',
  machinationsDocTitle: 'Machinations Documentation',
  machinationsDocSubtitle: 'Get up to speed with Machinations',
  machinationsCommunityTitle: 'Machinations Community',
  machinationsCommunitySubtitle: 'Get inspired by game designers just like you',
  machinationsDiscordTitle: 'Machinations on Discord',
  machinationsDiscordSubtitle: 'Join a live community to help you right now',
  finish: 'Finish',
  replay: 'Replay',
  learnMore: 'Do more tutorials',
  goToCommunity: 'Go to Community',
  openDiscord: 'Open Discord',
  tourNotFound: 'Tour not found!',
  tourNotCorrect: 'Tour is not correct!',
  noTemplateId: 'No template id!',
  noDiagramId: 'No diagram id!',
  thisDiagramHasNoTour: 'This diagram has no tour!',
  canNotDeleteTour: 'Can not delete tour!',
  tour: 'Diagram Tour',
  saveChanges: 'Save Changes',
  editTour: 'Edit Tour',
  copyTour: 'Copy Tour',
  removeTour: 'Remove Tour',
  templateTourCopied: 'Template Tour Copied!',
  tourNotValid: 'Tour not valid!',
  updateExistingTour: 'Update existing tour',
  openChart: 'Open chart',
  closeChart: 'Close Chart',
  clearChart: 'Clear Chart',
  resetZoom: 'Reset zoom',
  runMode: 'Run Mode',
  toAll: 'to all',
  shareOn: 'Share on',
  addToMyMachinations: 'Add to My Machinations',
  exportAsCsv: 'Export chart values as .csv',
  exportAsPng: 'Export chart as .png',
  runSpeedSeconds: 'Run Speed (seconds)',
  speed: 'Speed',
  steps: 'Steps',
  playTooltip: 'Play ({shortcut})',
  play: 'Play',
  resume: 'Resume',
  pauseTooltip: 'Pause ({shortcut})',
  pause: 'Pause',
  stepTooltip: 'Step ({shortcut})',
  resetTooltip: 'Reset ({shortcut})',
  quickPlays: 'Predictions',
  interactivePlays: 'Interactive Plays',
  quickPlaysPublicTooltip:
    'Add this diagram to your Machinations account to run Quick Plays',
  quickPlaysNoConnectionTooltip:
    'Please check your network connection. Quick Plays run on our servers.',
  runStepsPublicTooltip:
    'Add this diagram to your Machinations account to change the number of Steps',
  runSpeedPublicTooltip:
    'Add this diagram to your Machinations account to change the Run Speed',
  indicatorsPublicTooltip:
    'Add this diagram to your Machinations account to change Indicators',
  teamPlayHistoryPublicTooltip:
    'Add this diagram to your Machinations account to access Team Play History',
  teamSimulationsLimitReached:
    'Team limitation of {limit} monthly Predictions reached',
  pauseQuickPlays: 'Pause Quick Plays',
  fitToScreen: 'Fit to screen',
  fitToHeight: 'Fit to height',
  fitToWidth: 'Fit to width',
  publicDiagramNotAccessible:
    "Oops! The diagram has been deleted or isn't public anymore.",
  next: 'Next',
  welcomeToMachinationsTitle: 'Welcome to Machinations',
  welcomeToMachinationsSubtitle: 'Let’s get you set up',
  communityUsernamePrepend: 'Community/',
  onboardingQuestionCommunityUsername: 'Community Username',
  onboardingQuestionCompanyOrganization: 'Company/Organization',
  onboardingQuestionPhoneNumberPrivate: 'Phone Number (private)',
  phoneNumberPrivate: 'Phone Number (private)',
  getToKnowYouTitle: 'Personalize your experience',
  getToKnowYouSubtitle: 'Three easy questions to get you started',
  onboardingQuestionUserRole: 'What best describes you?',
  professional: 'Professional',
  hobbyist: 'Hobbyist',
  educator: 'Educator',
  researcher: 'Researcher',
  student: 'Student',
  onboardingQuestionJobtobedone: 'What do you want to design?',
  gameplay: 'Gameplay',
  gameEconomy: 'Game Economy',
  web3Economy: 'Web3 Economy',
  gameProgression: 'Game Progression',
  coreMetaLoop: 'Core/Meta Loop',
  levelDesign: 'Level Design',
  inGameEvents: 'In-game Events',
  other: 'Other',
  onboardingQuestionDevStage: 'What development stage is your game in?',
  concept: 'Concept',
  production: 'Production',
  launch: 'Launch',
  liveOps: 'Live Ops',
  onboardingQuestionSchoolUniversity: 'School or University',
  onboardingQuestionProjectDescription: 'Detailed project description',
  onboardingQuestionTeachersEmail: "Your Educator's Email Address",
  onboardingQuestionCourseName: 'Course Name',
  choosePlanTitle: 'Choose your plan',
  choosePlanSubtitle:
    "Select a free Public Plan or a free 14-Day {planName} trial. After your trial ends, <br /> you’ll automatically be downgraded to the Public Plan. <span class='m-link' url='https://machinations.io/pricing/' icon='true'>Compare Plans</span>.",
  areYouAnEducator:
    "Are you an Educator? You may apply for our <span class='m-link' screen='academia-plan'>Academia Plan</span>.",
  chooseProPlanSubtitle:
    'Select a free Public Plan or choose one of our Pro plans.',
  communityPlan: 'Public Plan',
  profileDiagramsPublic: 'Profile and Diagrams are public',
  oneEditor: '1 Editor',
  liveChatCommunitySupport: 'Live Chat & Community Support',
  freeForever: 'Free Forever.',
  freeForeverSeeEligibility:
    "Free Forever. <a href='https://machinations.io/terms-and-conditions/#tiers-and-eligibility' target='_blank'>See eligibility</a>",
  startCommunityAccount: 'Start Community Account',
  allDiagramsPrivate: 'All Diagrams are private',
  unlimitedEditors: 'Unlimited Editors',
  liveEditing: 'Live Editing',
  teamManagement: 'Team Management',
  prioritySupport: 'Priority Support',

  start14DaysTrial: 'Start 14-days Free Trial',
  tryForFree: 'Try for Free',
  startCommunity: 'Start a Free Public Plan',

  startStudioTrial: 'Start Professional Trial',
  startIndieTrial: 'Start Essential Trial',
  startStudioPlan: 'Start Professional Plan',
  startIndiePlan: 'Start Essential Plan',
  startStudioTrialTitle: 'Start your Professional Trial',
  startIndieTrialTitle: 'Start your Essential Trial',
  startProfessionalTrialSubtitle:
    'At the end of the trial your account will automatically be downgraded to the Public plan. <br /> All your private diagrams will remain private.',
  noCreditCardRequired: 'No Credit Card Required',
  onboardingQuestionJobTitle: 'What is your job title?',
  onboardingQuestionCompanySize: 'Company Size',
  '1to20People': '1 - 20 people',
  '20to150People': '20 - 150 people',
  '+150People': '+150 people',
  academiaPlanTitle: 'Apply for the Free Academia Plan',
  academiaPlanSubtitle:
    "The <span class='m-link' url='https://machinations.io/pricing/#academia'>Machinations Academia Plan</span> is a free specialized plan for <br /> qualified educators that use Machinations in their Curriculum",
  academiaPlanSuccessScreenSubtitle:
    "Please remember that you are required to add a link to <a class='m-link' href='https://machinations.io/' target='_blank'>machinations.io</a> on your institution’s website.<br /> Failing to do so within 30 days of your account’s activation will revert it to a Public Plan.",
  onboardingQuestionNumberOfStudents:
    'How many students will be using Machinations?',
  onboardingQuestionLessonPlan:
    'Do you already have a lesson plan for Machinations?',
  onboardingQuestionExamCurriculum:
    'Is Machinations part of the exam curriculum?',
  yes: 'Yes',
  no: 'No',
  onboardingQuestionMotivationToTeach:
    'What determined you to teach Machinations?',
  onboardingQuestionAcademiaBacklinkCheckbox:
    'I acknowledge that I am required to add a link to machinations.io on my institution’s website. Failing to do so within 30 days of my account’s activation will revert it to a Public Plan. <br /> I agree to only use this plan for academia, non-commercial and non-governmental purposes.',
  onboardingQuestionNewsletterCheckbox:
    'I wish to receive product and community updates. We promise we won’t spam you.',
  backToPlanOverview:
    "Back to <span class='m-link' screen='choose-plan'>Plan overview</span>",
  startAcademiaPlan: 'Start Academia Plan',
  pleaseType: 'Please type',
  pleaseSelect: 'Please select',
  fieldCannotBeEmpty: 'This field cannot be empty',
  usernameAllowedCharacters: 'Allowed Characters: a-z, A-Z, 0-9, -, _, +, .',
  usernameIsTaken: 'Username is already taken',
  invalidPhoneNumber: 'Invalid Phone Number',
  invalidNumber: 'Invalid Number',
  librariesLabel: 'Libraries',
  subscriptionLimitReached: 'Subscription limit reached.',
  freeSubscriptionUpgradeToAddLibraries: 'Item limit of {limitNo} reached',
  paidSubscriptionUpgradeToAddLibraries: 'Team Item limit of {limitNo} reached',
  upgradeToAddLibrariesButton: 'Upgrade to add more items',
  planUpgradeAddToTeamLibraries:
    'This feature is available only for paid plans.<br />To create your own team you need to:',
  planUpgrade: 'Upgrade your plan',
  maxNumberItemsPerLibraryWarning:
    'The maximum number of components per library is: {limitNo}',
  recoverDiagramDialogTitle: 'Recover last version',
  recoverDiagramDialogText:
    'Looks like your diagram is corrupted! Do you like to recover from last saved version?',
  linkDialogTextLabel: 'Text to show',
  defaultTextboxPlaceholder: 'Your text here...',
  emailNotConfirmed:
    'Your email is not confirmed, please check your inbox and follow instructions.',
  newTermsOfServiceDialogTitle: "We've updated our terms of service",
  newTermsOfServiceDialogContent:
    "Please note that, as of June 2021 we've updated our {termsAndConditionsUrl}. Starting now, all newly created User Content related to free accounts will be <b>public</b> and available in our {communityUrl} section. All existing diagrams (created prior to June 2021) will remain private. If you want your newly created User Content to be private, you can always upgrade to a paid account - check out our {pricingPlansUrl}. If you have any questions, we're here to <b>chat</b>.",
  termsAndConditionsUrlLabel: 'Terms of Service',
  communityUrlLabel: 'Community',
  comparePlansTitle: 'Pricing Plans',
  startTrialButton: 'Start Trial',
  payButton: 'Pay Now',
  cardDetails: 'Card details',
  startTrialCheckoutMessage:
    "You will now start your free 14 day trial period of the <b>Machinations {planPeriod} {planType}</b>. You can cancel your trial anytime within this period and you will not be charged. After the trial ends, you will be automatically charged <span style='font-weight: bold; color: #5A55F4;'>{planPrice}</span> for the <b> {planPeriod} {planType}. </b>",
  upgradePlanCheckoutMessage:
    "You will now upgrade to <b>Machinations {planPeriod} {planType} Plan</b>. You will be charged pro-rata for this billing period (amount proportional to the time left) and in the next billing period you will automatically be charged <span style='font-weight: bold; color: #5A55F4;'>{planPrice}</span>.",
  identityCheckoutMessage:
    'Your subscription will be automatically renewed periodically unless you cancel it before the next billing period. You will be invoiced and charged by Machinations S.àr.l, 8 Op Bierg, 8217, Mamer, Luxembourg, accounts at BGL BNP PARIBAS, VAT number: LU30464284, National registration number: B226688, Business Permit / Autorisation Nr 10089993/0.',
  cardStorageInfoCheckoutMessage:
    'Machinations S.àr.l does not store your credit card nor debit card information. All payment credit card or debit card data and processing is handled by Stripe Payments Europe, Limited, registered in Ireland, company number IE513174.',
  diagramStatusAllChangesSaved: 'All changes saved',
  diagramStatusChangesNotSaved: 'Changes not saved',
  diagramStatusNoChangesToSave: 'No changes to save',
  chartPanelLegendTitle: 'Nodes',
  qDeleteVersionPlayHistory: 'Delete Play History for this Version?',
  qDeleteTeamVersionPlayHistory: 'Delete Team Play History for this Version?',
  qDeleteSelectedPlays: 'Delete selected Plays?',
  qDeleteThisPlay: 'Delete this Play?',
  versionPlayHistoryWillBePermanentlyDeleted:
    'All play history for this version will be permanently deleted from this diagram',
  versionTeamPlayHistoryWillBePermanentlyDeleted:
    'All play history for this version will be permanently deleted from this diagram for all Team Members',
  selectedPlaysWillBePermanentlyDeleted:
    'These selected Plays will be permanently deleted from Server',
  thisPlayWillBePermanentlyDeleted:
    'This Play will be permanently deleted from this Diagram',
  leaveDiagramConfirmation: 'This will close the currently opened diagram',
  leaveDiagramConfirmationSubtitle:
    'Make sure to save your progress before switching',
  largeSelectionOfPlaysConfirmation:
    'You are about to add {nrOfPlays} plays to your current selection',
  largeSelectionOfPlaysConfirmationSubtitle:
    'Loading a total of {totalSteps} steps usually takes around {time}',
  selectedPlaysCount: '{count} Plays selected',
  playsCount: '{count} Play | {count} Plays',
  undoButtonTooltip: 'Undo',
  redoButtonTooltip: 'Redo',
  photoSizeExceeded: 'Photo size exceeded',
  maximumPhotoSize: 'The photo size should not exceed 500 KB.',
  incorrectFileType: 'Incorrect file type',
  selectedFileShouldBeImage: 'The selected file should be an image',
  noImageSelected: 'No image selected',
  selectAnImage: 'Please select an image',
  updateAndRefresh:
    'We’ve just released an update. Please save your progress and refresh.',
  teamSimulationsLimitMessage: 'Monthly number of Predictions in team exceeded',
  upcomingFeature: '<i>{featureName}</i> feature is coming soon',
  myProfile: 'My Profile',
  usageAndBilling: 'Usage & Billing',
  userApiKey: 'User API Key',
  userApiKeyCopied: 'User API Key copied to clipboard.',
  communityProfile: 'Community Profile',
  whatAreYouWorkingOn: 'What are you working on?',
  gameWebsiteStore: 'Game website/store',
  addAnotherGame: 'Add another game',
  completeYour: 'Complete your {profileType}',
  yourProfileIsComplete: 'Your {profileType} is complete!',
  publicProfile: 'Public Profile',
  userDetailsUpdated: 'User details successfully updated.',
  customerDetailsUpdated: 'Customer details successfully updated.',
  userDetailsNotUpdated: 'User details not updated.',
  inviteToTeam: 'Invite to Team',
  emailAddress: 'Email address',
  userAlreadyInTeam: 'The invited user is already part of the team.',
  memberRemovedFromTeam: '{memberName} was removed from team.',
  memberSuspendFromTeam: '{memberName} was suspended from team.',
  memberUnsuspendFromTeam: '{memberName} was unsuspended from team.',
  cannotBlockMember: 'Only active members can be suspended. ',
  cannotUnblockMember: 'Selected member is not suspended.',
  thisMember: 'This member',
  changePlan: 'Change Plan',
  billingSwitchTo: 'Switch to {billingCycle}',
  paymentPending: 'Payment pending',
  paymentPendingFor: 'Payment pending for {plan} Plan',
  planExpired: '{plan} Plan expired.',
  checkoutPaymentPendingFull:
    '{plan} Plan payment is still pending. Please check billing details.',
  checkoutPaymentPending: '{plan} Plan payment is still pending. Please ',
  resumeSubscription: 'Your subscription for {plan} Plan expired {end}',
  resumeSubscriptionAction: 'Resume',
  checkoutPaymentPendingRetry: 'retry payment',
  subscriptionWillEndOn: 'Your subscription will end on <br> {endDate}',
  subscriptionTrialUntil: 'Trial ends on <br> {endDate}',
  subscriptionTrialEnds: 'Trial ends on {endDate}',
  subscriptionTrialEndsInDays: 'Your free trial ends {count}.',
  subscriptionWillEndOnSingleLine: 'Your subscription will end on {endDate}',
  subscriptionWillChangeTo: 'Pending change to {plan} Plan',
  billingCycleWillChangeTo: 'Pending change to {billingCycle}',
  cancelSubscription: 'Cancel Subscription',
  yourNextBillingDate: 'Your next billing date <br> {nextBillingDate}',
  yourNextBillingDateIs: 'Your next billing date is {count}.',
  undoSubscriptionDeletion:
    'Scheduled subscription deletion was cancelled successfully.',
  invoiceNumber: 'Invoice number',
  billingDate: 'Billing date',
  selectPaymentMethod: 'Select Payment Method',
  suspendTeamMember: 'Suspend team member?',
  confirmSuspendMembers:
    '{memberName} will no longer have access to team diagrams and will be suspended from your team.',
  unsuspendTeamMember: 'Unsuspend team member?',
  confirmUnsuspendMembers:
    '{memberName} will have access to team diagrams and will be added to your team.',
  startPlanTrial: 'Start {plan} Trial',
  startPlan: 'Start {plan} Plan',
  planChangedTo: 'Plan changed to {planName}',
  planChangeScheduledTo: 'Change to {planName} plan pending',
  planNotChanged: 'Plan not changed',
  paymentMethod: 'Payment Method',
  resumeSubscriptionMember:
    "{team} Team's subscription is expired since {end}. Contact the team owner to resume subscription.",
  asACompany: 'As a Company',
  nextInvoice: 'Next Invoice',
  invalidTaxId: 'Invalid Tax ID',
  cannotValidateTaxId: 'Cannot validate Tax ID',
  viesValidation: 'VIES validated at {validationDate}',
  welcomeToYour: 'Welcome to your',
  welcomeTo: 'Welcome to',
  teamJoinFailed:
    "An error occurred while trying to join {teamName} team. Please try again. If the error persists, don’t hesitate to <a target='_blank' href='mailto:support@machinations.io?subject=Team join failed'>contact us</a>.",
  joinTeam: 'Join team',
  skipForNow: 'Skip for now',
  invitedYouToJoin:
    'You have been invited to join <b>{teamName}</b>, owned by <b>{teamOwner}</b>.',
  joiningTeamGivesAccess:
    "Joining a team will give you access to the team's diagrams.",
  oneTeamPerAccount: 'One team per account',
  cantJoinTeamMember:
    'You’re already part of <b>{currentTeamName}</b>. Contact its owner and ask to be removed, in order to accept the invite to <b>{teamName}</b>.',
  activeSubscription: 'Active subscription',
  cantJoinTeamOwner:
    'You have an active subscription. To join <b>{teamName}</b>, you need to first cancel your subscription.',
  inviteRemainActive:
    "Unless you decline it, the invite will remain active. You can access it later in <span class='m-invitations-dialog-link'>the Notifications center.</span>",
  openInvitationLink:
    "<a href='javascript:void(0);' class='toast-link-open-invitations'>Open invitation.</a>",
  aTeam: 'a team',
  aUser: 'an user',
  openSettings: 'Open Settings',
  contactOwner: 'Contact owner',
  accept: 'Accept',
  theTeam: 'the team',
  namePlaceholder: 'Name',
  jobAndCompanyPlaceholder: 'Job title & Company',
  locationPlaceholder: 'Location',
  aboutMePlaceholder: 'About me',
  myGamesPlaceholder: 'My Games',
  noDataAvailable: 'No data available',
  globalSettings: 'Global Settings',
  lastConnectionStyle: 'Use last used connection style',
  resultsDecimals: 'Results Decimals',
  all: 'all',
  offlineAlert:
    'We’re experiencing issues communicating with the server. Please check your Internet connection.',
  confirmationDialogInteractiveNodestitle: 'Interactive Nodes on diagram',
  confirmationDialogInteractiveNodesText:
    "This diagram has at least one Interactive Node. Interactive Nodes are only clickable during Interactive Play. Quick Plays treats them as passive Nodes. Read about <a href='https://machinations.io/docs/activation-modes/' target='_blank'>Trigger Modes</a>",
  confirmationDialogInteractiveNodesActionContinue: 'Continue',
  confirmationDialogInteractiveNodesActionCancel: 'Back',
  cryptoBannerTitle: 'Validate your Play to Earn game economy',
  cryptoBannerDescription:
    "Are you building a Web3 game? We're working with Web3 game developers to support the future of Web3 Blockchain games. These collaborations combine the power of Monte Carlo simulations with dedicated Web3 game economy expertise. Please book some time with a consultant for more information.",
  myDiagrams: 'My Diagrams',
  teamFolder: 'Team Folder',
  activityFeed: 'Activity Feed',
  activity: 'Activity',
  viewCommunityProfile: 'View Community Profile',
  showingResults: 'Showing {count} result for | Showing {count} results for',
  diagramName: 'Diagram Name',
  comp: 'Comp.',
  lastEdit: 'Last Edit',
  designerName: 'Designer Name',
  lastSeen: 'Last Seen',
  cannotCountDiagrams: 'Cannot count diagrams',
  nameAZ: 'Name A-Z',
  nameZA: 'Name Z-A',
  newestCreated: 'Newest created',
  oldestCreated: 'Oldest created',
  newestModified: 'Newest modified',
  oldestModified: 'Oldest modified',
  mostRecent: 'Most Recent',
  mostPopular: 'Most Popular',
  allTeam: 'All team',
  switchToPublicProfileToast:
    "This action requires a public account. Go to <a href='javascript: void(0)' class='toast-link-account'> Account details </a> to change your account’s privacy settings.",
  'Hello, World!': 'Crafting',
  'Copy of Hello, World!': 'Crafting',
  'Loot and Craft': 'Crafting',
  'Web 3.0 Economy': 'Web3',
  'Copy of Web 3.0 Economy': 'Web3',
  'Copy of Loot and Craft': 'Crafting',
  openPaymentPlan: 'Open Payment Plan',
  existingActiveSubscription:
    'You already have an active subscription for {email}. Access your Payment Plan settings to update preferences.',
  memberOfTheTeam:
    'You are a member of {teamName} team. To update your payment plan, please contact your team administrator.',
  VerifyInstanceID: 'Verify instance ID',
  textInstanceID: 'Instance ID',
  textEmailSender:
    'Email address of the sender of all outgoing emails initiated by the Machinations app',
  textEmailHost: 'The IP address or domain name of the outgoing SMTP server',
  textEmailPort: 'TCP/IP port to connect to the outgoing SMTP server',
  textEmailSecure:
    "If 'true' the connection will use TLS when connecting to outgoing SMTP server. If false then TLS is used if server supports the STARTTLS extension. In most cases set this value to true if you are connecting to port 465. For port 587 or 25 keep it false",
  textEmailUsername:
    'Authentication username to connect to the outgoing SMTP server',
  textEmailPassword:
    'Authentication password to connect to the outgoing SMTP server',
  placeholderEmailSender: 'example-no-reply@your-domain.com',
  placeholderEmailHost: '',
  placeholderEmailPort: '',
  placeholderEmailSecure: '',
  placeholderEmailUsername: '',
  placeholderEmailPassword: '',
  cannotDeleteOwnerAccount: 'Cannot delete owner account',
  invalidIPAddrOrDomain: 'Invalid IP address and/ro domain',
  invalidUsernameOrActivity: 'Invalid username or activityId',
  activityFeedError: 'An error occurred while retrieving the activity feed.',
  openInMachinations: 'Open in Machinations',
  yourProfile: 'Your profile',
  privateUser: 'Private user',
  publishedNewDiagram: 'published a new diagram',
  updatedADiagram: 'updated a diagram',
  diagramStartedTrending: 'diagram started trending',
  addedNewTemplate: 'added a new template',
  templateStartedTrending: 'Template started trending',
  startedFollowing: 'started following',
  startedTrending: 'started trending',
  shortcuts: 'Keyboard Shortcuts',
  menuKeyboardShortcuts: 'Keyboard shortcuts',
  openShortcuts: 'Open Shortcuts',
  fitSelection: 'Fit Selection to Screen',
  sendToBack: 'Send to Back',
  sendToFront: 'Send to Front',
  playStep: 'Play Step',
  runAll: 'Run All',
  showGuides: 'Show Guides',
  showGrid: 'Show Grid',
  showTooltips: 'Show Tooltips',
  stop: 'Stop',
  deleteCPText: 'Delete',
  duplicateCPText: 'Duplicate',
  copyCPText: 'Copy',
  createLayerTooltip: 'Create layer',
  duplicateLayerTooltip: 'Duplicate layer',
  moveSelectionLayerTooltip: 'Move selection to layer',
  removeLayerTooltip: 'Remove layer',
  showLayersTooltip: 'Show Layers',
  hideLayersTooltip: 'Hide Layers',
  showOutlinerTooltip: 'Show Outliner',
  hideOutlinerTooltip: 'Hide Outliner',
  globalVariablesTooltip:
    "Hold their value throughout the diagram, and can be accessed by any formula.  <a href='https://machinations.io/docs/interface-basics/#global-variables/' target='_blank'>Read more</a>",
  triggerTooltip:
    "How Nodes behave during Play. <a href='https://machinations.io/docs/activation-modes/' target='_blank'>Read more</a>",
  passiveTooltip:
    "<img width='224' height='224' src='/resources/images/passiveNodesTooltip.gif'><b>Passive Nodes</b></br> Can fire only in response to a trigger generated by another element.",
  actionTooltip: 'The action the node needs to take during Play.',
  capacityTooltip:
    "Behaviour if Capacity is reached. <a href='https://machinations.io/docs/pools/#overflow' target='_blank'>Read more</a>",
  limitTooltip: "Limit the Pool's storage capacity.",
  displayTooltip: 'The value above which Resources are displayed as a number.',
  resourceFilterTooltip:
    "Resource Filter. <a href='https://machinations.io/docs/filter/' target='_blank'>Read more</a>",
  conversionSingleTooltip: 'One conversion per Step',
  conversionMultipleTooltip: 'All possible conversions in 1 Step',
  interactiveTooltip:
    'During Play interact with the up/down arrows. Interaction unavailable for Predictions.',
  interactiveInitialTooltip: 'Starting value',
  interactiveStepValueTooltip: 'Interaction value',
  delayActionDelayTooltip:
    'Postpones transfer for all inputted Resources for the number of Steps specified on the output connection.',
  delayActionQueueTooltip:
    "Processes 1 Resource at a time. Postpones each Resource's transfer for the number of Steps specified on the output connection.",
  resourceConnectionTransferInstantTooltip:
    'All inputted Resources are transferred simultaneously, at the beginning of the Step.',
  resourceConnectionTransferIntervalTooltip:
    'Inputted Resources are transferred individually, within 1 Step.',
  resourceConnectionFilterTooltip:
    "Only allows the transfer of Resources whose colour matches the filter's.",
  resourceConnectionShuffleOriginTooltip:
    'Randomise the transfer of differently coloured Resources coming from the origin Node.',
  stateConnectionFilterTooltip:
    "Only take input for state changes from Resources whose colour matches the filter's.",
  formulaTooltip:
    "Supports functions from the math.js library. <a href='https://machinations.io/docs/registers/math-js-functions/' target='_blank'>Examples</a>",
  formulaInteractiveTooltip: 'Interactive Registers do not support Formulas.',
  lastChangeDate: 'Last change was ',
  web3BtnVideoView: 'See Why',
  web3GameEconomyHealth: 'Game Economy Health',
  web3GameUnderReview: 'Under Review by Machinations',
  web3GameVerified: 'Verified by Machinations',

  moreBy: 'More from',
  moreByOwner: 'More by owner',

  disclaimerTitle: 'Disclaimer',

  noDiagramTitle: 'Nothing Published Yet',
  nothingAdded: 'Nothing Added Yet',
  noDiagramText: "See what's being designed by our Community",
  privateAccountTitle: 'This Profile is Private',
  privateAccountText: 'See what else is being designed by our Community',
  resourcesAllTitle: 'On Game Design & Machinations',
  resourcesAllDescription:
    'stuff we found interesting and relevant for you, reach out if you’d like to feature a guest post',
  start14DaysTrial: 'Start a free 14 day trial',
  comparePlansDescription:
    'Enjoy Machinations for free or start a free Pro trial while we make you a custom quote',
  getCustomQuote: 'Get Custom Quote',

  //cookies
  cookiePreferences: 'Cookie Preferences',
  necessaryCookies: 'Essential Cookies',
  authenticationCookiesDescription: 'Used for authentication control',
  securityCookiesDescription:
    'Used for preventing unauthorized actions/access to your Machinations account',
  paymentsCookiesDescription: 'Used for processing payments',
  communityFeed: 'Community feed',
  communityFeedDescription:
    'Used to remember where you left off while browsing the Machinations Community feed',
  loadPerformance: 'Load performance',
  loadPerformanceDescription:
    'Used to improve loading performance for Machinations',

  functionalityCookies: 'Functionality Cookies',
  supportChat: 'Support chat',
  supportChatDescription: 'Used to enable the support chat',

  optionalCookies: 'Analytics and Marketing Cookies',
  googleAnalyticsCookies: 'Google Analytics',
  googleAnalyticsCookiesDescription:
    'Helps us improve website and app usability and content, and to measure the effectiveness of marketing campaigns',
  segmentCookiesDescription:
    'Helps us improve our business operations by integrating multiple analytics and marketing tools into a single platform',
  hotjarCookiesDescription:
    'Helps us understand how you interact with Machinations, allowing us to improve usability, optimize content and identify areas of improvement',
  hubspotCookiesDescription:
    'Helps us provide better customer service and streamline your buying process',
  linkedinCookiesDescription:
    'Helps us show you relevant advertising within your LinkedIn Feed',
  mixpanelCookiesDescription:
    'Helps us gain insights about which features you find useful, allowing us to improve your experience',
  customerioCookiesDescription:
    'Helps us create better personalized emails, tailored to your interests.',

  optionalCookies: 'Analytics and Marketing Cookies',
  googleAnalyticsCookies: 'Google Analytics',
  googleAnalyticsCookiesDescription:
    'Helps us improve website and app usability and content, and to measure the effectiveness of marketing campaigns',
  segmentCookiesDescription:
    'Helps us improve our business operations by integrating multiple analytics and marketing tools into a single platform',
  hotjarCookiesDescription:
    'Helps us understand how you interact with Machinations, allowing us to improve usability, optimize content and identify areas of improvement',
  hubspotCookiesDescription:
    'Helps us provide better customer service and streamline your buying process',
  linkedinCookiesDescription:
    'Helps us show you relevant advertising within your LinkedIn Feed',
  mixpanelCookiesDescription:
    'Helps us gain insights about which features you find useful, allowing us to improve your experience',
  customerioCookiesDescription:
    'Helps us create better personalized emails, tailored to your interests.',

  /*----- Single Words -----*/
  /*----- put here any single word which may be reused in different parts of the application -----*/
  wordCapitalMachinations: 'Machinations',
  wordAnd: 'and',
  wordCapitalAnd: 'And',
  wordOr: 'or',
  wordCapitalOr: 'Or',
  wordSave: 'save',
  wordCapitalSave: 'Save',
  wordDone: 'done',
  wordCapitalDone: 'Done',
  wordBio: 'bio',
  wordCapitalBio: 'Bio',
  wordPassword: 'password',
  wordCapitalPassword: 'Password',
  wordRole: 'role',
  wordCapitalRole: 'Role',
  wordFacebook: 'facebook',
  wordCapitalFacebook: 'Facebook',
  wordLinkedin: 'linkedIn',
  wordCapitalLinkedin: 'LinkedIn',
  wordTwitter: 'twitter',
  wordCapitalTwitter: 'Twitter',
  wordDiscord: 'discord',
  wordCapitalDiscord: 'Discord',
  wordChange: 'change',
  wordCapitalChange: 'Change',
  wordJoined: 'joined',
  wordCapitalJoined: 'Joined',
  wordUpgrade: 'upgrade',
  wordCapitalUpgrade: 'Upgrade',
  wordNext: 'next',
  wordCapitalNext: 'Next',
  wordBack: 'back',
  wordCapitalBack: 'Back',
  wordBuy: 'buy',
  wordCapitalBuy: 'Buy',
  wordPublic: 'public',
  wordCapitalPublic: 'Public',
  wordPrivate: 'private',
  wordCapitalPrivate: 'Private',
  wordDocumentation: 'documentation',
  wordCapitalDocumentation: 'Documentation',
  wordConfirm: 'confirm',
  wordCapitalConfirm: 'Confirm',
  warning: 'Warning',
  wordUnlimited: 'unlimited',
  wordCapitalUnlimited: 'Unlimited',
  wordOf: 'of',
  wordCapitalFeature: 'Feature',
  wordMembers: 'members',
  wordCapitalMembers: 'Members',
  wordGames: 'games',
  wordCapitalGames: 'Games',
  wordProfile: 'profile',
  wordCapitalProfile: 'Profile',
  wordRejected: 'rejected',
  wordCapitalRejected: 'Rejected',
  wordDeclined: 'declined',
  wordCapitalDeclined: 'Declined',
  wordAccepted: 'accepted',
  wordCapitalAccepted: 'Accepted',
  wordMonth: 'month',
  wordCapitalMonth: 'Month',
  wordYear: 'year',
  wordCapitalYear: 'Year',
  wordUsage: 'usage',
  wordCapitalUsage: 'Usage',
  wordAmount: 'amount',
  wordCapitalAmount: 'Amount',
  wordVoid: 'void',
  wordCapitalVoid: 'Void',
  wordInvalid: 'invalid',
  wordCapitalInvalid: 'Invalid',
  wordPaid: 'paid',
  wordCapitalPaid: 'Paid',
  wordPay: 'pay',
  wordCapitalPay: 'Pay',
  wordCard: 'card',
  wordCapitalCard: 'Card',
  wordStripe: 'stripe',
  wordCapitalStripe: 'Stripe',
  wordSuspend: 'suspend',
  wordCapitalSuspend: 'Suspend',
  wordUnsuspend: 'unsuspend',
  wordCapitalUnsuspend: 'Unsuspend',
  wordTeam: 'team',
  wordCapitalTeam: 'Team',
  wordDecline: 'decline',
  wordCapitalDecline: 'Decline',
  wordCheck: 'check',
  wordCapitalUnknown: 'Unknown',
  wordSince: 'since',
  wordCapitalCrypto: 'Web3',
  wordCapitalDesigners: 'Designers',
  wordCapitalFollow: 'Follow',
  wordCapitalUnfollow: 'Unfollow',
  wordCapitalFollowing: 'Following',
  wordCapitalFollowers: 'Followers',
  wordCapitalSort: 'Sort:',
  wordCapitalOldest: 'Oldest',
  wordYou: 'you',
  wordCapitalYou: 'You',
  wordCapitalLike: 'Like',
  wordCapitalUnlike: 'Unlike',
  wordCapitalYour: 'Your',
  wordLiked: 'liked',
  wordForked: 'forked',
  wordCapitalFile: 'File',
  wordCapitalHelp: 'Help',
  wordCapitalRename: 'Rename',
  wordCapitalPrint: 'Print',
  wordCapitalShare: 'Share',
  wordCapitalSettings: 'Settings',
  wordCapitalNew: 'New',
  wordCapitalOpen: 'Open',
  wordCapitalDelete: 'Delete',
  wordNone: 'none',
  wordAll: 'all',
  wordCapitalTitle: 'Title',
  wordCapitalCopy: 'Copy',
  wordCapitalCopied: 'Copied',
  wordCapitalGenerate: 'Generate',
  wordCapitalDescription: 'Description',
  wordCapitalOwner: 'Owner',
  wordCapitalCreated: 'Created',
  wordCapitalComponents: 'Components',
  wordCapitalSource: 'Source',
  wordCapitalDrain: 'Drain',
  wordCapitalGate: 'Gate',
  wordCapitalTrader: 'Trader',
  wordCapitalConverter: 'Converter',
  wordCapitalRegister: 'Register',
  wordCapitalDelay: 'Delay',
  wordCapitalText: 'Text',
  wordCapitalEdit: 'Edit',
  wordCapitalFormatting: 'Formatting',
  wordCapitalZoom: 'Zoom',
  wordCapitalSelection: 'Selection',
  wordCapitalSimulations: 'Simulations',
  wordCapitalBold: 'Bold',
  wordCapitalItalic: 'Italic',
  wordCapitalUnderline: 'Underline',
  wordCapitalDuplicate: 'Duplicate',
  wordCapitalResume: 'Resume',
  wordCapitalRedo: 'Redo',
  wordCapitalCut: 'Cut',
  wordCapitalPaste: 'Paste',
  wordCapitalGroup: 'Group',
  wordCapitalPlay: 'Play',
  wordCapitalPause: 'Pause',
  wordCapitalStop: 'Stop',
  wordCapitalPool: 'Pool',
  wordCapitalNotifications: 'Notifications',
  wordCapitalTag: 'Tag',
  wordCapitalDisconnected: 'Disconnected',
  wordCapitalHistory: 'History',
  wordCapitalPlays: 'Plays',
  wordCapitalFilter: 'Filter',
  wordCapitalAuthor: 'Author',
  wordCapitalLibraries: 'Libraries',
  wordCapitalReset: 'Reset',
  wordCapitalStep: 'Step',
  wordCapitalStatus: 'Status',
  wordCapitalFork: 'Fork',
  wordCapitalStroke: 'Stroke',
  wordCapitalFill: 'Fill',
  wordDefault: 'default',
  wordExpired: 'expired',
  wordEdit: 'edit',
  wordAccess: 'access',
  wordCapitalCustom: 'Custom',
  wordCapitalSales: 'Sales',
  wordToday: 'today',
  wordCapitalPreferences: 'Preferences',
  wordCapitalSurveys: 'Surveys',
  wordCapitalSecurity: 'Security',
  wordCapitalAuthentication: 'Authentication',
  wordCapitalPayments: 'Payments',
  wordCapitalSegment: 'Segment',
  wordCapitalHotjar: 'Hotjar',
  wordCapitalHubspot: 'Hubspot',
  wordCapitalMixpanel: 'Mixpanel',
  wordCapitalCustomerio: 'Customer.io',
  wordCapitalCancel: 'Cancel',
  wordCapitalAdd: 'Add',
  wordCapitalMessage: 'Message',
  wordCapitalSubject: 'Subject',
  wordCapitalCookies: 'Cookies',
  wordCapitalCompany: 'Company',
  /*----- END OF Single Words -----*/

  communityDescMenuItem:
    'Discover the largest systems thinking community. Like & fork models. Follow & get in touch with creators.',

  viewAll: 'View All',
  markAllAsRead: 'Mark as read',
  errorSubmitFormMessage:
    'We encountered an error while submitting your form. Please try again!',
  failedToFetchNotificationMessage: 'Failed to fetch notification message.',
  formMessageInfo:
    'Please enter the details of your request. A member of our support staff will respond as soon as possible',
  bySendingThisMessageAgreeTo: 'By sending this message I agree to',
  termsOfService: 'Terms of Service',
  privacyPolicy: 'Privacy',
  companyDetails: 'Company Details',
  companyDetails1: 'Machinations S.àr.l, 8217, Mamer, Luxembourg;',
  companyDetails2: 'Accounts at: BGL BNP PARIBAS;',
  companyDetails3: 'VAT nr: LU30464284; National registration nr: B226688;',
  companyDetails4: 'Business Permit / Auth. Nr: 10089993/0;',
  thanksForContactingUs: 'Thanks for contacting us',
  someoneWillGetInTouch: 'Someone will get in touch with you shortly',
  tryMachinations: 'Try Machinations',
  visitOurCommunity: 'Visit our Community',
  nameRequired: 'Name is required',
  nameAtLeast3Chars: 'Name must be at least 3 characters',
  emailRequired: 'Email is required',
  mailMustBeValid: 'Email must be valid',
  jobTitleRole: 'Job Title / Role',
  jobRequired: 'Job Title / Role is required',
  messageRequired: 'Message is required',
  placeholderPleaseTypeMinChars: 'Please type your message (min. 20 chars)',
  subjectRequired: 'Subject is required',
  companyRequired: 'Company is required',
  backedBy: 'Backed by',
  termsAndConditions: 'Terms & Conditions',
  letsConnect: "Let's connect",
  readMore: 'Read more',
  readMoreCapitalized: 'Read More',
  readLess: 'Read less',
  readLessCapitalized: 'Read Less',
  getYourFreeCopy: 'Get your free copy',
  downloadForFree: 'Download for free',
  backToMachinations: 'Back to Machinations',
  thankYouDownloadStarted: 'Thank you! Your download has started',
  liveopsWhitepaper: 'Liveops Whitepaper',
  iso: 'ISO 27001',
  tryItYourself: "Try it for yourself. It's free",
  licenseInfo:
    'CCO 1.0 Universal Public Domain Dedication Creative Commons license',
  downloadThe: 'Download the',
  liveopsHandbookDescription:
    'LiveOps: Turning Chaos into Predictive Clarity Handbook',
  availableForWork: "Available for work",
  diagramCTA: 'Enjoying what you see?<br>Show your appreciation by saving it with a click!',
  thisDiagram: 'this diagram',
  getInTouch: 'Get in touch',
  exploreMore: 'Explore more',
  beTheFirst: 'Be the first to',
  forkedDiagramMessage: 'This diagram is a forked version, originally created by',
  forkedDiagramMissingSource: 'This diagram is a forked version, with hidden attributions due to privacy settings or content removal.',
  originalDiagram: 'Original diagram',
  loadMore: "Load more",
}

module.exports = language
