export enum PRODUCTS {
  COMMUNITY = 0, //
  ESSENTIAL = 1,
  PROFESSIONAL = 2,
  POWER = 3,
  WEB3 = 9,
  TRIAL = 10,
  UPGRADE = 11, // to be removed
  INDIE = 12, //
  PRO = 13, //
  ENTERPRISE = 14,
  VAULT = 15, //
}

export const PRODUCT_KEYS = {
  [PRODUCTS.COMMUNITY]: 'community',
  [PRODUCTS.VAULT]: 'vault',
  [PRODUCTS.INDIE]: 'indie',
  [PRODUCTS.PRO]: 'pro',
  [PRODUCTS.ENTERPRISE]: 'enterprise',
}
