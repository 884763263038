import { UserType } from '~/domain/interfaces/UserTypes'

export enum DesignerTypes {
  NEWEST = 1,
  POPULAR = 2,
  HIGHLIGHTED = 3,
}

export interface DesignersStoreState {
  featured?: UserType[]
  forHire?: UserType[]
  designersList?: UserType[]
  total?: number
  featuredLoaded?: boolean,
  fetching?: boolean
}

export enum DesignerStoreMutations {
  IS_FETCHING = 'setIsFetching'
}

export enum DesignersStoreActions {
  FETCH_FEATURED = 'designers/fetchFeatured',
  FETCH_NEWEST = 'designers/fetchNewest',
  FETCH_POPULAR = 'designers/fetchPopular',
  FETCH_DESIGNERS = 'designers/fetchDesignersList',
}

export type HireDesignerRequest = {
  fullName: string
  email: string
  company: string
  projectName: string
  message?: string
  designerUsername: string
  recaptchaToken?: any
  sendCopy?: boolean
}
