
import Vue, { PropType } from 'vue'

export type MobileCommunitySubMenu = {
  title: string
  key: string
  url?: string
  slug?: string
  icon?: string
}
export default Vue.extend({
  name: 'MobileCommunitySubMenu',
  props: {
    menuItem: {
      required: true,
    },
    menuItemId: {
      required: true,
    },
    subMenuItems: {
      type: Array as PropType<MobileCommunitySubMenu[]>,
      default: () => {
        return [
          {
            title: 'Discord',
            key: '__discord',
            url: 'https://discord.gg/EqKqWAJ',
            icon: '$commDiscordIcon',
          },
          {
            title: 'Twitter',
            key: '__twitter',
            url: 'https://twitter.com/machinationsio',
            icon: '$twitterSocialIcon',
          },
          {
            title: 'Youtube',
            key: '__youtube',
            url: 'http://www.youtube.com/channel/UCnui50w5BC_P7pfrF0XwoKg',
            icon: '$commYoutubeIcon',
          },
        ]
      },
    },
  },
  methods: {
    switchDrawer() {
      this.$emit('switchDrawer');
    },
    communityItemPressed(page: string) {
      this.switchDrawer();
      this.$router.push(`/community/${page}`)
    },
  }
})
