import { SegmentEventParams } from '~/domain/repositories/SegmentRepo'
import { CommunityRepo } from '@repositories/CommunityRepo'
import { Context } from '@nuxt/types'

export default {
  // eslint-disable-next-line
  trackSegmentEvent({ state }: any, data: SegmentEventParams) {
    if (typeof state.trackSegmentEvent === 'function') {
      state.trackSegmentEvent(
        null,
        data.name,
        data.category,
        data.action,
        data.label,
        data.value || '',
        data.misc
      )
    }
  },

  async fetchActivityFeedPage({ commit, dispatch }, context: Context) {
    await dispatch(
      'wp/fetchPageBySlug',
      {
        context,
        slug: 'activity-feed',
      },
      { root: true }
    )
    commit('activityFeedResetPage')
  },

  async fetchActivityFeed(
    { commit, state },
    params: { context: Context; user: any }
  ) {
    const repoCommunity = new CommunityRepo(params.context)

    //get current user if logged in for username

    const activityFeedContent = await repoCommunity.fetchActivityFeed({
      count: state.activityFeed.recordsPerPage,
      page: state.activityFeed.lastPage + 1,
      username: params.user ? params.user.username : '',
    })

    commit('activityFeedAddList', activityFeedContent.list)
    commit('activityFeedIncrementPage', activityFeedContent)

    return activityFeedContent
  },

  async markActivityAsViewed(
    { commit, state },
    params: { context: Context; id: number }
  ) {
    //mark them as viewd locally
    commit('setActivitiesAsViewd', params.id)
    commit('checkIfHasNew')
  },

  async sendActivityIds(
    { commit, state },
    params: { context: Context; username: string }
  ) {
    //send gethered ids to mark as view in backend
    const repoCommunity = new CommunityRepo(params.context)
    const activityResponse = await repoCommunity.markActivityFeedAsViewed({
      ids: state.activityFeed.idsViewed,
      username: params.username,
    })
    commit('resetIdsViewd')
  },
}
