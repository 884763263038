import { UserType, UserLoginInfoType, UserBannerSpotlight } from "@interfaces/UserTypes";
import { Diagram } from "./DiagramTypes";
import { ActivityFeedType } from "./ActivityFeedTypes";

export interface AppAttributes {
  route?: string;
  designer_emails?: string[];
  diagrams_staff_picks?: string[];
}

export interface UserConnections {
  following: UserType[];
  followers: UserType[];
}

export interface WpDataType {
  // Config
  machinationsAppUrl?: string;

  // Community
  // TODO: rename; designersOfTheMonth currently used only for login popup to select profile avatars.
  designersOfTheMonth?: UserType[];

  designerTabs?: UserType[][];
  diagramTabs?: Diagram[][];

  // Profile
  user?: UserType;
  userDiagrams?: Diagram[];
  userLikedDiagrams?: Diagram[];
  userConnections?: UserConnections;

  // Diagram
  diagram?: Diagram;
  relatedDiagrams?: Diagram[];

  // All profiles
  users?: UserType[];

  page?: number;
  total?: number;
  itemsPerPage?: number;

  stats?: {
    designers: number;
    diagrams: number;
  };

  // Activity Feed.
  activityFeeds?: ActivityFeedType[];
  activityFeedComplete?: boolean;
  diagrams?: Diagram[];
  autoLoadingLimit?: number;

  // Spotlight User for Slider
  spotlights?: UserBannerSpotlight[];
}

export enum CommunitySlugs {
  NONE = "",
  ACTIVITY = "activity-feed",
  COMMUNITY = "community",
  DESIGNERS = "designers",
  DIAGRAMS = "diagrams",
}

export interface CommunityActivityFeed {
  list: any[];
  lastPage: number;
  recordsPerPage: number;
  hasMore: boolean;
  hasNew: boolean;
  idsViewed: number[];
}

export interface CommunityStoreState {
  wpData: WpDataType;
  wpAtts: AppAttributes[];
  trackSegmentEvent: any;
  user: UserLoginInfoType;
  userLoginSet: boolean;
  spotlights: any[];
  activeSlug: CommunitySlugs;
  activityFeed: CommunityActivityFeed;
}

export enum CommunityStoreActions {
  FETCH_ACTIVITY_FEED = "community/fetchActivityFeed",
  FETCH_ACTIVITY_FEED_PAGE = "community/fetchActivityFeedPage",
}

export enum CommunityStoreMutations {
  SET_INITIAL_STATE = "setInitialState",
  SET_ACTIVE_SLUG = "setActiveSlug"
}

export enum COMMUNITY_FILTERS {
  DESIGNERS="designers",
  DIAGRAMS="diagrams",
  DESIGNERS_OF_THE_MONTH = "designersOfTheMonth",
  DESIGNERS_FOR_HIRE = "designersForHire",
  DIAGRAMS_OF_THE_MONTH = "diagramsOfTheMonth",
  NEW_TEMPLATES = "newTemplates",
  TAGS="tags"
}