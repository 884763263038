var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"auth-header d-flex flex-row justify-content-end align-center"},[_vm._v("\n  "+_vm._s(/* (!) m-notifications-box-wrapper must be rendered, do not use v-if */)+"\n  "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userIsAuthenticated),expression:"userIsAuthenticated"}],ref:"notificationsBoxWrapper",staticClass:"m-notifications-box-wrapper"}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userIsFetching && !_vm.userIsAuthenticated),expression:"userIsFetching && !userIsAuthenticated"}],staticClass:"desktop-profile-view"},[_c('div',{staticClass:"d-flex justify-content-end align-center",style:({
          flex: 1,
          'gap': '32px',
        })},[_c('v-skeleton-loader',{attrs:{"type":"avatar","size":"48"}})],1)]),_vm._v(" "),_c('ClientOnly',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.userIsAuthenticated),expression:"userIsAuthenticated"}]},[_c('div',{staticClass:"user-menu d-flex flex-row align-center"},[(_vm.user)?_c('div',[_c('div',{staticClass:"desktop-profile-view",on:{"click":_vm.onProfilePressed}},[_c('ProfileAvatar',{attrs:{"user":_vm.user,"size":48}})],1),_vm._v(" "),(_vm.showProfileBox)?_c('div',{staticClass:"profile-card-view show-box-anim",on:{"click":() => {
                _vm.showProfileBox = false
              }}},[_c('homepage-user-header-box')],1):_vm._e()]):_c('div')])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.userIsAuthenticated && _vm.fetchUserComplete && !_vm.userIsFetching),expression:"!userIsAuthenticated && fetchUserComplete && !userIsFetching"}]},[_c('div',{staticClass:"login-menu d-flex flex-row align-center justify-content-end"},[_c('span',{staticClass:"btn-menu d-flex justify-content-center align-center",style:({
            color: 'var(--brand-color)',
            border: 'none',
            'background-color': 'transparent',
          }),on:{"click":_vm.login}},[_vm._v("\n          Log in\n        ")]),_vm._v(" "),_c('span',{staticClass:"btn-menu register d-flex justify-content-center align-center",staticStyle:{"color":"white"},on:{"click":_vm.registerClick}},[_vm._v("\n          Register\n        ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }