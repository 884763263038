
import Vue, { PropType } from 'vue'
import AppActions from '~/domain/constants/AppActions'
import { openNewTabFallback } from '~/domain/helpers/helpers'

export default Vue.extend({
  name: 'AuthHeader',
  data() {
    return {
      showProfileBox: false,
    }
  },
  computed: {
    userIsFetching() {
      return this.$store.getters["userIsFetching"];
    },
    registerUrl() {
      return `${this.$config.apiBaseURL}/register`
    },
    userIsAuthenticated() {
      return this.$store.getters['userIsAuthenticated']
    },
    user() {
      return this.$store.getters['authenticatedUser']
    },
    fetchUserComplete() {
      return this.$store.getters["fetchUserComplete"];
    },
  },
  methods: {
    login: function () {
      this.$auth.login()
    },
    onProfilePressed: function () {
      this.showProfileBox = !this.showProfileBox
    },
    registerClick: async function(){
      await this.$store.dispatch(AppActions.TRACK_SIGN_UP_EVENT, {
        context: this,
        location: 'header',
        link:
          this.registerUrl || null,
      }).then(() => {
        openNewTabFallback(this.registerUrl);
      })
    },
    onVisiblityChange() {
      if (document.visibilityState === 'visible') {
        this.$auth.fetchUser();
      }
    },
  },
  mounted() {
    if(process.browser) {
      window.addEventListener('visibilitychange', this.onVisiblityChange);
    }
    const notifWrapper = this.$refs?.notificationsBoxWrapper as HTMLElement
    if (this.user && this.$notifications && notifWrapper) {
      this.$notifications.remount({
        ...this.$notificationsPluginOptions,
        username: this.user.username,
      })
    }
  },
  beforeDestroy() {
    if(process.browser) {
      window.removeEventListener('visibilitychange', this.onVisiblityChange);
    }
  },
})
