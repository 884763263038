import Vue from 'vue'
import { ContextMenuState } from '~/domain/interfaces/ContextMenuTypes'

export default {
  setIsVisible(
    state: ContextMenuState,
    data: { id: string; visible: boolean; target: any }
  ) {
    state.isVisible = data.visible
    state.target = data.target
    Vue.set(state.visibleMenu, `${data.id}`, data.visible)
  },
  setIsUpdating(state: ContextMenuState, val: boolean) {
    state.isUpdating = val
  },
}
