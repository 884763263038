import { DiagramTypes } from '@interfaces/DiagramTypes'
import { PRODUCTS } from '@constants/PricingConst'

export interface ProductConfig {
  id?: number | null
  active?: boolean
  name: string
  nameCode: string
  price: ProductConfigPrice

  privateLibraries: ProductConfigValue | null
  runSteps: ProductConfigValue | null

  teamComponents: ProductConfigValue | null
  teamLibraries: ProductConfigValue | null

  teamMembers: number | null

  teamSimulations: ProductConfigValue | null
  teamTags: ProductConfigValue | null
  versionHistory: ProductConfigValue | null

  productDescription: any //eslint-disable-line

  lockedPlan: boolean | null | undefined
  privateProfile: boolean | null
  apiKey: boolean | null

  comparePlans?: {
    features: any // eslint-disable-line
    comingSoon: any // eslint-disable-line
  }
}

export interface ProductConfigValue {
  limit: number
  message: string
}

export interface ProductConfigPrice {
  [key: string]: string | number
  monthly: number
  yearly: number
  currency: string
  getQuoteURL: string
}

export function formatNumber(
  x: string | number | null,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = 'en-US'
): string {
  if (x === null || x === undefined) return ''
  if (typeof x === 'string') x = parseFloat(x)
  if (isNaN(x)) return ''

  if (x > 1000) {
    options.maximumFractionDigits = 2
  } else if (options.maximumFractionDigits === -1) {
    options.maximumFractionDigits = getNumberOfDecimals(x)
  }
  return x.toLocaleString(locale, options)
}

export function getNumberOfDecimals(x: number) {
  const number = x.toString()
  // verify if number 0.000005 is represented as "5e-6"
  if (number.indexOf('e-') > -1) {
    // eslint-disable-next-line
    const [base, trail] = number.split('e-')
    const deg = parseInt(trail, 10)
    return deg
  }
  // count decimals for number in representation like "0.123456"
  if (Math.floor(x) !== x) {
    return x.toString().split('.')[1].length || 0
  }
  return 0
}

// TRADUCE TEXTELE

export function translateProductConfig(
  prodConf,
  instanceBind
): ProductConfig[] {
  if (prodConf && prodConf.length) {
    prodConf.forEach((product: any) => {
      //eslint-disable-line
      if (
        product.productDescription &&
        typeof product.productDescription === 'object'
      ) {
        Object.entries(product.productDescription).forEach((entry) => {
          const [key, value] = entry
          if (typeof value === 'string') {
            product.productDescription[key] = instanceBind.$t(value)
          } else if (Array.isArray(value) && value.length) {
            for (let i = 0; i < value.length; i++) {
              if (value[i] && value[i].text) {
                product.productDescription[key][i].text = instanceBind.$t(
                  value[i].text
                )
              }
            }
          }
        })
      }

      if (product.comparePlans && typeof product.comparePlans === 'object') {
        Object.entries(product.comparePlans).forEach((entry1) => {
          const [key1, value1] = entry1 as [string, any] //eslint-disable-line
          if (value1 && typeof value1 === 'object') {
            Object.entries(value1).forEach((entry2) => {
              const [key2, value2] = entry2 as [string, any] //eslint-disable-line
              if (value2) {
                if (typeof value2 === 'object') {
                  if (
                    value2.valueConfig &&
                    value2.message &&
                    product[value2.valueConfig] &&
                    product[value2.valueConfig].limit
                  ) {
                    product.comparePlans[key1][key2].message =
                      typeof product[value2.valueConfig].limit !== 'undefined'
                        ? typeof value2.message !== 'undefined'
                          ? instanceBind.$tc(
                              value2.message,

                              product[value2.valueConfig].limit,
                              {
                                limit: formatNumber(
                                  product[value2.valueConfig].limit
                                ),
                              }
                            )
                          : formatNumber(product[value2.valueConfig].limit)
                        : instanceBind.$t('wordCapitalUnlimited')
                  } else if (value2.value && value2.message) {
                    product.comparePlans[key1][key2].message =
                      typeof value2.message === 'string'
                        ? instanceBind.$tc(
                            value2.message,

                            typeof value2.value === 'number'
                              ? value2.value
                              : parseInt(value2.value),
                            { limit: formatNumber(value2.value) }
                          )
                        : formatNumber(value2.value)
                  } else {
                    product.comparePlans[key1][key2].message = instanceBind.$t(
                      value2.message
                    )
                  }
                } else if (typeof value2 === 'string') {
                  product.comparePlans[key1][key2] = instanceBind.$t(value2)
                }
              }
            })
          }
        })
      }
    })

    return prodConf
  } else return []
}

export function translatePlanFeatures(planFeatures, instanceBind): any {
  //eslint-disable-line
  const result = {} as { [key: string]: any } //eslint-disable-line

  if (planFeatures && typeof planFeatures === 'object') {
    Object.entries(planFeatures).forEach((entry) => {
      const [key, value] = entry as [string, any] //eslint-disable-line
      result[key] = {}
      for (let i = 0; i < value.length; i++) {
        result[key][value[i]] = instanceBind.$t(value[i])
      }
    })
  }

  return result
}

export function planHasCustomQuote(planId: number) {
  return planId === PRODUCTS.TRIAL || planId === PRODUCTS.ENTERPRISE
}

// eslint-disable-next-line
export function debounce<T extends Function>(func: T, timeout = 300) {
  let timer: any = 0
  // eslint-disable-next-line
  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func(args)
    }, timeout)
  }
}

export function isInViewport(elem: HTMLElement): boolean {
  const rect = elem.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function convertDiagramTypeIntoSortByParam(
  diagramType: DiagramTypes
): string {
  switch (diagramType) {
    case DiagramTypes.NEWEST:
      return 'last_modified'
    case DiagramTypes.POPULAR:
      return 'likeCount'
    default:
      return 'last_modified'
  }
}

export function getQueryParamsString(params: any = {}): string {
  return Object.keys(params).reduce((acc, key) => {
    if (acc !== '') {
      acc += '&'
    }
    acc += key + '='

    if (Array.isArray(params[key])) {
      acc += encodeURIComponent(params[key].join(','))
    } else {
      acc += encodeURIComponent(params[key])
    }

    return acc
  }, '')
}

export function formatMatchingSubstring(
  v: string,
  query: string | null | undefined
) {
  return v && query
    ? v.replace(
        new RegExp(`${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'ig'),
        "<span class='m-matching-substring'>$&</span>"
      )
    : v
}

export function formatNumberWithSuffix(
  x: string | number | null,
  options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 },
  locale = 'en'
): string {
  return formatNumber(
    x,
    {
      notation: 'compact',
      compactDisplay: 'short',
      ...options,
    } as Intl.NumberFormatOptions,
    locale
  )
}

export function validateURL(str: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i'
  )
  return !!pattern.test(str)
}

export function validateDiagramFuid(str: string): boolean {
  const pattern = new RegExp(/^[a-zA-Z0-9]+$/)
  return !!pattern.test(str)
}

export function decodeHTMLEntities(rawStr: string) {
  return rawStr
    .replace(/&amp;/g, '&')
    .replace(/&#(\d+);/g, (match, dec) => `${String.fromCharCode(dec)}`)
}

/** sanitize string for url */
export function sanitizeString(stringToSanitize) {
  return stringToSanitize
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
    .toLowerCase()
}

export interface WindowLinkedin extends Window {
  lintrk?: Function
}

export function trackLinkedinConversion(
  conversionId: string | number | undefined
) {
  const lintrk = (window as WindowLinkedin)?.lintrk
  if (lintrk && conversionId) {
    lintrk('track', { conversion_id: conversionId })
  }
}

export function trackLinkedinAds(action: string) {
  switch (action) {
    case 'signup-intent':
      trackLinkedinConversion(14887290)
      break
    case 'book-demo':
      trackLinkedinConversion(14954194)
      break
  }
}

export function openNewTabFallback(url: string) {
  if (process.browser) {
    const win = window.open(url, '_blank')
    if (!win) {
      window.location.href = url
    }
  }
}

export function isExternalLink(link: string, nuxtBaseURL: string) {
  return link && link.indexOf('http') !== -1 && link.indexOf(nuxtBaseURL) === -1
}

export function serializeQueryString(obj: Object, prefix?: string) {
  var str: string[] = [],
    p
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      str.push(
        v !== null && typeof v === 'object'
          ? serializeQueryString(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v)
      )
    }
  }
  return str.join('&')
}
