import { Template } from './TemplateTypes'
import { UserType } from './UserTypes'
export interface Tag {
  name: string
  id?: number
  color?: string
  // owner: number;
  // selected: number | null;
}

interface Person {
  firstName: string
  lastName: string
  username: string
  email: string
}

export interface Diagram {
  isCurrentUserOwner: boolean
  id?: number | string // diagram fuid
  fuid: string // diagram fuid
  name: string // diagram name
  thumbnail: string // diagram img source
  defaultThumbnail: string
  link: string // diagram link
  owner: UserType
  lastEdit: string // diagram owner last online
  objectCount: string // diagram owner comp
  likeCount: string | number // diagram likes number
  viewCount: string | number // diagram views number
  forkCount: string | number
  shareCount: string | number // diagram shares number
  commentCount: string // diagram comments number
  shares: any //eslint-disable-line
  kind?: string
  likedByAuthenticatedUser?: boolean
  isTemplate: boolean
  isDiagram: boolean
  isTest: boolean
  description?: string
  source?: string
  publicUrl?: string
  contributors?: any
}

export interface DiagramsFileKey {
  (args: {
    context: any;
    params: {
      fileKeys?: (string | undefined)[] | undefined;
      authenticatedUser?: string;
      diagramView?: boolean;

    };
  });
}

export enum DiagramTypes {
  NEWEST = 1,
  POPULAR = 2,
  HIGHLIGHTED = 3,
}

export interface DiagramStoreState {
  highlighted: Diagram[]
  newest: Diagram[]
  popular: Diagram[]
  total: number,
  totalNewestDiagrams: number,
  totalPopularDiagrams: number,
  currentDiagram: Diagram | null
  relatedDiagrams: Diagram[] | null
  diagramAction: null | DiagramAction
  highlightedLoaded?: boolean,
  newTemplates: Diagram[],
  fetching?: boolean,
  glossary?: Diagram[],
}

export interface DiagramAction {
  type: string
  fuid: string
}

export enum DiagramStoreActions {
  GET_DIAGRAMS = 'diagrams/getDiagrams',
  GET_DIAGRAMS_OF_THE_MONTH = 'diagrams/getDiagramsOfTheMonth',
  GET_NEW_TEMPLATES = 'diagrams/getNewTemplates',
  GET_GLOSSARY_DIAGRAMS = 'diagrams/getGlossaryDiagrams'
}

export enum DiagramStoreMutations {
  SET_NEWEST = 'setNewest',
  SET_POPULAR = 'setPopular',
  SET_HIGHLIGHTED = 'setHighlighted',
  SET_HIGHLIGHTED_LOADED = 'setHighlightedLoaded',
  SET_TOTAL = 'setTotal',
  IS_FETCHING = 'setIsFetching',
  SET_GLOSSARY = 'setGlossary',
}

export const instanceIsDiagram = (obj: Diagram | Template) => {
  return 'fuid' in obj
}
