import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b70fcd2 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _7c47120e = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _72d4a766 = () => interopDefault(import('../pages/articles/index.vue' /* webpackChunkName: "pages/articles/index" */))
const _5a207dc6 = () => interopDefault(import('../pages/aup.vue' /* webpackChunkName: "pages/aup" */))
const _22985982 = () => interopDefault(import('../pages/careers/index.vue' /* webpackChunkName: "pages/careers/index" */))
const _70ea4c2a = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _66e01001 = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _6968e8e4 = () => interopDefault(import('../pages/docs/index.vue' /* webpackChunkName: "pages/docs/index" */))
const _01ff5c5c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _31980a52 = () => interopDefault(import('../pages/game-design-tool.vue' /* webpackChunkName: "pages/game-design-tool" */))
const _6ed8d6cb = () => interopDefault(import('../pages/games.vue' /* webpackChunkName: "pages/games" */))
const _049a7a07 = () => interopDefault(import('../pages/glossary/index.vue' /* webpackChunkName: "pages/glossary/index" */))
const _78877c60 = () => interopDefault(import('../pages/liveops-whitepaper.vue' /* webpackChunkName: "pages/liveops-whitepaper" */))
const _485ae054 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _2bf9a15a = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _f939d592 = () => interopDefault(import('../pages/search-results.vue' /* webpackChunkName: "pages/search-results" */))
const _7b2dae46 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _7a6f0177 = () => interopDefault(import('../pages/community/explore.vue' /* webpackChunkName: "pages/community/explore" */))
const _c09e33ea = () => interopDefault(import('../pages/community/see-all.vue' /* webpackChunkName: "pages/community/see-all" */))
const _6b604483 = () => interopDefault(import('../pages/resources/academic-research-publications.vue' /* webpackChunkName: "pages/resources/academic-research-publications" */))
const _77a94690 = () => interopDefault(import('../pages/articles/author/_slugAuthor/index.vue' /* webpackChunkName: "pages/articles/author/_slugAuthor/index" */))
const _d1556594 = () => interopDefault(import('../pages/articles/category/_slugCategory/index.vue' /* webpackChunkName: "pages/articles/category/_slugCategory/index" */))
const _f472a51a = () => interopDefault(import('../pages/articles/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/page/_pageNumber" */))
const _8b6fe04a = () => interopDefault(import('../pages/careers/job/_jobSlug.vue' /* webpackChunkName: "pages/careers/job/_jobSlug" */))
const _e5645fee = () => interopDefault(import('../pages/articles/author/_slugAuthor/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/author/_slugAuthor/page/_pageNumber" */))
const _513345a3 = () => interopDefault(import('../pages/articles/category/_slugCategory/page/_pageNumber.vue' /* webpackChunkName: "pages/articles/category/_slugCategory/page/_pageNumber" */))
const _7609b921 = () => interopDefault(import('../pages/academy/_academySlug.vue' /* webpackChunkName: "pages/academy/_academySlug" */))
const _5f6e7fde = () => interopDefault(import('../pages/articles/_slugPost.vue' /* webpackChunkName: "pages/articles/_slugPost" */))
const _ac4a62bc = () => interopDefault(import('../pages/docs/_docSlug.vue' /* webpackChunkName: "pages/docs/_docSlug" */))
const _02e3d8bf = () => interopDefault(import('../pages/glossary/_slug.vue' /* webpackChunkName: "pages/glossary/_slug" */))
const _8bb23a8a = () => interopDefault(import('../pages/community/_communityUser/profile.vue' /* webpackChunkName: "pages/community/_communityUser/profile" */))
const _68101fdc = () => interopDefault(import('../pages/community/_communityUser/_userAction.vue' /* webpackChunkName: "pages/community/_communityUser/_userAction" */))
const _0048bb14 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _3b70fcd2,
    pathToRegexpOptions: {"strict":true},
    name: "about___en___default"
  }, {
    path: "/academy",
    component: _7c47120e,
    pathToRegexpOptions: {"strict":true},
    name: "academy___en___default"
  }, {
    path: "/articles",
    component: _72d4a766,
    pathToRegexpOptions: {"strict":true},
    name: "articles___en___default"
  }, {
    path: "/aup",
    component: _5a207dc6,
    pathToRegexpOptions: {"strict":true},
    name: "aup___en___default"
  }, {
    path: "/careers",
    component: _22985982,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en___default"
  }, {
    path: "/contact",
    component: _70ea4c2a,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en___default"
  }, {
    path: "/disclaimer",
    component: _66e01001,
    pathToRegexpOptions: {"strict":true},
    name: "disclaimer___en___default"
  }, {
    path: "/docs",
    component: _6968e8e4,
    pathToRegexpOptions: {"strict":true},
    name: "docs___en___default"
  }, {
    path: "/en",
    component: _01ff5c5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/game-design-tool",
    component: _31980a52,
    pathToRegexpOptions: {"strict":true},
    name: "game-design-tool___en___default"
  }, {
    path: "/games",
    component: _6ed8d6cb,
    pathToRegexpOptions: {"strict":true},
    name: "games___en___default"
  }, {
    path: "/glossary",
    component: _049a7a07,
    pathToRegexpOptions: {"strict":true},
    name: "glossary___en___default"
  }, {
    path: "/liveops-whitepaper",
    component: _78877c60,
    pathToRegexpOptions: {"strict":true},
    name: "liveops-whitepaper___en___default"
  }, {
    path: "/partners",
    component: _485ae054,
    pathToRegexpOptions: {"strict":true},
    name: "partners___en___default"
  }, {
    path: "/pricing",
    component: _2bf9a15a,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en___default"
  }, {
    path: "/search-results",
    component: _f939d592,
    pathToRegexpOptions: {"strict":true},
    name: "search-results___en___default"
  }, {
    path: "/terms-and-conditions",
    component: _7b2dae46,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en___default"
  }, {
    path: "/community/explore",
    component: _7a6f0177,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___en___default"
  }, {
    path: "/community/see-all",
    component: _c09e33ea,
    pathToRegexpOptions: {"strict":true},
    name: "community-see-all___en___default"
  }, {
    path: "/en/about",
    component: _3b70fcd2,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/academy",
    component: _7c47120e,
    pathToRegexpOptions: {"strict":true},
    name: "academy___en"
  }, {
    path: "/en/articles",
    component: _72d4a766,
    pathToRegexpOptions: {"strict":true},
    name: "articles___en"
  }, {
    path: "/en/aup",
    component: _5a207dc6,
    pathToRegexpOptions: {"strict":true},
    name: "aup___en"
  }, {
    path: "/en/careers",
    component: _22985982,
    pathToRegexpOptions: {"strict":true},
    name: "careers___en"
  }, {
    path: "/en/contact",
    component: _70ea4c2a,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/disclaimer",
    component: _66e01001,
    pathToRegexpOptions: {"strict":true},
    name: "disclaimer___en"
  }, {
    path: "/en/docs",
    component: _6968e8e4,
    pathToRegexpOptions: {"strict":true},
    name: "docs___en"
  }, {
    path: "/en/game-design-tool",
    component: _31980a52,
    pathToRegexpOptions: {"strict":true},
    name: "game-design-tool___en"
  }, {
    path: "/en/games",
    component: _6ed8d6cb,
    pathToRegexpOptions: {"strict":true},
    name: "games___en"
  }, {
    path: "/en/glossary",
    component: _049a7a07,
    pathToRegexpOptions: {"strict":true},
    name: "glossary___en"
  }, {
    path: "/en/liveops-whitepaper",
    component: _78877c60,
    pathToRegexpOptions: {"strict":true},
    name: "liveops-whitepaper___en"
  }, {
    path: "/en/partners",
    component: _485ae054,
    pathToRegexpOptions: {"strict":true},
    name: "partners___en"
  }, {
    path: "/en/pricing",
    component: _2bf9a15a,
    pathToRegexpOptions: {"strict":true},
    name: "pricing___en"
  }, {
    path: "/en/search-results",
    component: _f939d592,
    pathToRegexpOptions: {"strict":true},
    name: "search-results___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _7b2dae46,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en"
  }, {
    path: "/resources/academic-research-publications",
    component: _6b604483,
    pathToRegexpOptions: {"strict":true},
    name: "resources-academic-research-publications___en___default"
  }, {
    path: "/en/community/explore",
    component: _7a6f0177,
    pathToRegexpOptions: {"strict":true},
    name: "community-explore___en"
  }, {
    path: "/en/community/see-all",
    component: _c09e33ea,
    pathToRegexpOptions: {"strict":true},
    name: "community-see-all___en"
  }, {
    path: "/en/resources/academic-research-publications",
    component: _6b604483,
    pathToRegexpOptions: {"strict":true},
    name: "resources-academic-research-publications___en"
  }, {
    path: "/",
    component: _01ff5c5c,
    pathToRegexpOptions: {"strict":true},
    name: "index___en___default"
  }, {
    path: "/en/articles/author/:slugAuthor",
    component: _77a94690,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor___en"
  }, {
    path: "/en/articles/category/:slugCategory",
    component: _d1556594,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory___en"
  }, {
    path: "/en/articles/page/:pageNumber?",
    component: _f472a51a,
    pathToRegexpOptions: {"strict":true},
    name: "articles-page-pageNumber___en"
  }, {
    path: "/en/careers/job/:jobSlug?",
    component: _8b6fe04a,
    pathToRegexpOptions: {"strict":true},
    name: "careers-job-jobSlug___en"
  }, {
    path: "/en/articles/author/:slugAuthor?/page/:pageNumber?",
    component: _e5645fee,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor-page-pageNumber___en"
  }, {
    path: "/en/articles/category/:slugCategory?/page/:pageNumber?",
    component: _513345a3,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory-page-pageNumber___en"
  }, {
    path: "/articles/author/:slugAuthor",
    component: _77a94690,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor___en___default"
  }, {
    path: "/articles/category/:slugCategory",
    component: _d1556594,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory___en___default"
  }, {
    path: "/articles/page/:pageNumber?",
    component: _f472a51a,
    pathToRegexpOptions: {"strict":true},
    name: "articles-page-pageNumber___en___default"
  }, {
    path: "/careers/job/:jobSlug?",
    component: _8b6fe04a,
    pathToRegexpOptions: {"strict":true},
    name: "careers-job-jobSlug___en___default"
  }, {
    path: "/en/academy/:academySlug",
    component: _7609b921,
    pathToRegexpOptions: {"strict":true},
    name: "academy-academySlug___en"
  }, {
    path: "/en/articles/:slugPost",
    component: _5f6e7fde,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slugPost___en"
  }, {
    path: "/en/docs/:docSlug",
    component: _ac4a62bc,
    pathToRegexpOptions: {"strict":true},
    name: "docs-docSlug___en"
  }, {
    path: "/en/glossary/:slug",
    component: _02e3d8bf,
    pathToRegexpOptions: {"strict":true},
    name: "glossary-slug___en"
  }, {
    path: "/en/community/:communityUser?/profile",
    component: _8bb23a8a,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-profile___en"
  }, {
    path: "/articles/author/:slugAuthor?/page/:pageNumber?",
    component: _e5645fee,
    pathToRegexpOptions: {"strict":true},
    name: "articles-author-slugAuthor-page-pageNumber___en___default"
  }, {
    path: "/articles/category/:slugCategory?/page/:pageNumber?",
    component: _513345a3,
    pathToRegexpOptions: {"strict":true},
    name: "articles-category-slugCategory-page-pageNumber___en___default"
  }, {
    path: "/en/community/:communityUser?/:userAction?",
    component: _68101fdc,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-userAction___en"
  }, {
    path: "/academy/:academySlug",
    component: _7609b921,
    pathToRegexpOptions: {"strict":true},
    name: "academy-academySlug___en___default"
  }, {
    path: "/articles/:slugPost",
    component: _5f6e7fde,
    pathToRegexpOptions: {"strict":true},
    name: "articles-slugPost___en___default"
  }, {
    path: "/docs/:docSlug",
    component: _ac4a62bc,
    pathToRegexpOptions: {"strict":true},
    name: "docs-docSlug___en___default"
  }, {
    path: "/en/:slug",
    component: _0048bb14,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en"
  }, {
    path: "/glossary/:slug",
    component: _02e3d8bf,
    pathToRegexpOptions: {"strict":true},
    name: "glossary-slug___en___default"
  }, {
    path: "/community/:communityUser?/profile",
    component: _8bb23a8a,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-profile___en___default"
  }, {
    path: "/community/:communityUser?/:userAction?",
    component: _68101fdc,
    pathToRegexpOptions: {"strict":true},
    name: "community-communityUser-userAction___en___default"
  }, {
    path: "/:slug",
    component: _0048bb14,
    pathToRegexpOptions: {"strict":true},
    name: "slug___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
