import { render, staticRenderFns } from "./HeaderNavBar.vue?vue&type=template&id=e95544be"
import script from "./HeaderNavBar.vue?vue&type=script&lang=ts"
export * from "./HeaderNavBar.vue?vue&type=script&lang=ts"
import style0 from "./HeaderNavBar.vue?vue&type=style&index=0&id=e95544be&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageHeaderLogo: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/page/header/PageHeaderLogo.vue').default,HeaderItemCard: require('/Users/teodor/Documents/Workspace/website/wp-nuxt/components/header/HeaderItemCard.vue').default})
